import React from 'react'
import clsx from 'clsx'

const Checkbox = ({
    className,
    hasError,
    value,
    ...props
}) => (
    <input
        type="checkbox"
        checked={value}
        className={(clsx(
            'h-4 w-4 text-primary focus:ring-primary-700 border-gray-300 rounded cursor-pointer',
            className,
        ))}
        {...props}
    />
)

export default Checkbox
