import React from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import * as constants from '@connections/constants'
import Spinner from '../../utils/Spinner'
import RegisterPageWithState from './RegisterPageWithState'
import InvitationInvalidPage from './InvitationInvalidPage'
import useAcceptInvitationPageQuery from './useAcceptInvitationPageQuery'

const {
    INVITATION_STATUS_PENDING: PENDING,
} = constants

const AcceptInvitationPageWithState = () => {
    const { id } = useParams()
    const history = useHistory()
    const location = useLocation()
    const token = new URLSearchParams(location.search).get('token')
    const {
        isFetching,
        invitation,
    } = useAcceptInvitationPageQuery(id)
    if (isFetching) {
        return <Spinner />
    }
    if (invitation.status !== PENDING) {
        return (
            <InvitationInvalidPage
                onDismiss={() => history.push('/')}
            />
        )
    }
    return (
        <RegisterPageWithState
            token={token}
            invitation={invitation}
        />
    )
}

export default AcceptInvitationPageWithState
