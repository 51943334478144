import { useQuery, gql } from '@apollo/client'

export const CREATE_INVITATION_MODAL_QUERY = gql`
    query createInvitationModalQuery {
        roles {
            id
            type
        }
    }
`

const useCreateInvitationModalQuery = () => {
    const {
        data = {},
        error,
        loading,
        refetch,
    } = useQuery(CREATE_INVITATION_MODAL_QUERY)
    return {
        error,
        isFetching: loading,
        refetch,
        roles: data.roles || [],
    }
}

export default useCreateInvitationModalQuery
