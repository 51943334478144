import { useState } from 'react'
import { mergeQueryResults } from '../../utils/graphql'

const useFetchMore = (fetchMore, data, key) => {
    const [isFetchingMore, setIsFetchingMore] = useState(false)

    const hasNextPage = data && data[key] && data[key].pageInfo.hasNextPage
    const handleFetchMore = async () => {
        setIsFetchingMore(true)
        const after = data[key].pageInfo.endCursor
        await fetchMore({
            variables: { after },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                setIsFetchingMore(false)
                return mergeQueryResults(previousResult, fetchMoreResult, key)
            },
        })
        setIsFetchingMore(false)
    }

    return [
        handleFetchMore,
        isFetchingMore,
        hasNextPage,
    ]
}

export default useFetchMore
