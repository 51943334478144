import React from 'react'
import Table from '../../utils/Table'
import BookingsPageTableRow from './BookingsPageTableRow'
import BookingsPageTableHeaderRow from './BookingsPageTableHeaderRow'

const BookingsPageTable = ({
    bookings,
}) => (
    <Table data-testid="BookingsPageTable">
        <thead>
            <BookingsPageTableHeaderRow />
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
            {bookings.map((booking) => (
                <BookingsPageTableRow
                    key={booking.id}
                    booking={booking}
                />
            ))}
        </tbody>
    </Table>
)

export default BookingsPageTable
