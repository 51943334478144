import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteForkModal = ({
    isOpen,
    fork,
    isLoading,
    onClose,
    onConfirm,
}) => (
    <ConfirmModal
        isOpen={isOpen}
        title={<Trans>Delete fork</Trans>}
        description={(
            <Trans>
                Are you sure you want to delete fork with iata code <strong>{fork.code}</strong>?
            </Trans>
        )}
        confirmButtonText={<Trans>Yes, delete fork</Trans>}
        loading={isLoading}
        disabled={isLoading}
        onClose={onClose}
        onConfirm={onConfirm}
    />
)

export default DeleteForkModal
