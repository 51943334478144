import React from 'react'
import useNotifications from '../../hooks/useNotifications'
import UpdateGiftcardModal from './UpdateGiftcardModal'
import useGiftcardFormState from './useGiftcardFormState'
import useUpdateGiftcardMutation from './useUpdateGiftcardMutation'

const UpdateGiftcardModalWithState = ({
    giftcard,
    isOpen,
    onClose,
    onCompleted,
    onError,
    imageList,
}) => {
    const [updateGiftcard, { loading }] = useUpdateGiftcardMutation({ onCompleted, onError })
    const formState = useGiftcardFormState({
        paxFileId: giftcard.booking.data?.paxFile?.ID,
        code: giftcard.data.giftcard.GiftCardCode,
        amount: giftcard.data.giftcard.Amount,
        from: giftcard.data.buyer,
        to: giftcard.data.recipient,
        message: giftcard.data.message,
        headerImage: giftcard.data.headerImage,
        footerImage: giftcard.data.footerImage,
    })
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <UpdateGiftcardModal
            isOpen={isOpen}
            onClose={() => {
                onClose()
                formState.resetForm()
            }}
            isLoading={loading}
            formState={formState}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const variables = {
                            bookingId: giftcard.booking.id,
                            bookingItemId: giftcard.id,
                            input: formState.valuesToInput()
                        }
                        await updateGiftcard({
                            variables,
                        })
                        dispatchSuccess()
                        onClose()
                    } catch (error) {
                        dispatchGraphqlError(error, {}, true)
                    }
                }
            }}
            imageList={imageList}
        />
    )
}

export default UpdateGiftcardModalWithState
