import React from 'react'
import clsx from 'clsx'
import { gql } from '@apollo/client'
import { Trans } from '@lingui/macro'
import TableCell from '../../utils/TableCell'
import useAppState from '../../hooks/useAppState'
import UsersPageTableRowActionButtonWithState from './UsersPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment UsersPageTableRow on User {
        id
        type
        firstName
        lastName
        email
        role {
            id
            type
        }
    }
`

const UsersPageTableRow = ({
    user,
    onDeleteUserSuccess,
}) => {
    const {
        id,
        role,
        email,
        lastName,
        firstName,
    } = user
    const { currentUser } = useAppState()
    const isYou = id === currentUser.id
    return (
        <tr data-testid="UsersPageTableRow">
            <TableCell hasText>
                <div className="text-sm leading-5">
                    <div className="font-medium text-gray-900">
                        {`${firstName} ${lastName}`}
                    </div>
                    <div className="text-gray-500">
                        {email}
                    </div>
                </div>
            </TableCell>
            <TableCell hasText>
                {role.type}
            </TableCell>
            <TableCell>
                <span
                    className={clsx(
                        'px-2 inline-flex text-xs leading-5 font-semibold rounded-full',
                        'bg-green-100 text-green-800',
                        isYou && 'bg-blue-100 text-blue-800',
                    )}
                >
                    {isYou
                        ? <Trans>You</Trans>
                        : <Trans>Active</Trans>}
                </span>
            </TableCell>
            <TableCell hasText className="text-right">
                <UsersPageTableRowActionButtonWithState
                    user={user}
                    onDeleteUserSuccess={onDeleteUserSuccess}
                />
            </TableCell>
        </tr>
    )
}

UsersPageTableRow.FRAGMENT = FRAGMENT

export default UsersPageTableRow
