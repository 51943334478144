import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const GiftCardPageTableRowActionButton = ({
    onUpdate,
}) => (
    <DropdownMenu
        position="bottom-start"
        button={<ActionButton as="span" />}
    >
        <>
            <DropdownMenuItem
                iconName="edit"
                onClick={onUpdate}
            >
                <Trans>Update giftcard</Trans>
            </DropdownMenuItem>
        </>
    </DropdownMenu>
)

export default GiftCardPageTableRowActionButton
