import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'

const validation = createFormValidation([{
    path: 'email',
    validate: (email) => email !== '',
    message: <Trans>The email field is required</Trans>,
}, {
    path: 'password',
    validate: (password) => password !== '',
    message: <Trans>The password field is required</Trans>,
}])

const useLoginFormState = () => (
    useFormState({
        email: '',
        password: '',
        rememberMe: false,
    }, {
        validation,
    })
)

export default useLoginFormState
