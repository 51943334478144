import React, { useState } from 'react'
import ForksPage from './ForksPage'
import useForksPageQuery from './useForksPageQuery'
import CreateForkModalWithState from './CreateForkModalWithState'

const ForksPageWithState = () => {
    const [createModelIsOpen, setCreateModalIsOpen] = useState(false)
    const {
        hasMore,
        refetch,
        isFetchingMore,
        handleFetchMore,
        cheapestPriceForks,
    } = useForksPageQuery()
    return (
        <>
            <CreateForkModalWithState
                isOpen={createModelIsOpen}
                onClose={() => setCreateModalIsOpen(false)}
                onCompleted={() => refetch()}
            />
            <ForksPage
                hasMore={hasMore}
                onFetchMore={handleFetchMore}
                isFetchingMore={isFetchingMore}
                cheapestPriceForks={cheapestPriceForks}
                onCreateFork={() => setCreateModalIsOpen(true)}
                onDeleteForkCompleted={() => refetch()}
                onUpdateForkCompleted={() => refetch()}
            />
        </>
    )
}

export default ForksPageWithState
