import React from 'react'
import { Trans } from '@lingui/macro'
import Modal, { ModalTitle, ModalBody } from '../../utils/Modal'
import Button from '../../utils/Button'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'

const CreateForkModal = ({
    isOpen,
    onClose,
    isLoading,
    formState,
    onSubmit,
}) => {
    const {
        getErrorMessages,
        getNativeInputProps,
    } = formState
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalTitle
                title={<Trans>Create new price fork</Trans>}
                onClose={onClose}
            />
            <ModalBody>
                <FormGroup
                    label={<Trans>Iata code</Trans>}
                    htmlFor="code"
                >
                    <FormInput
                        id="code"
                        className="max-w-lg"
                        errors={getErrorMessages('code')}
                        {...getNativeInputProps('code')}
                    />
                </FormGroup>
                <div className="flex">
                    <FormGroup
                        label={<Trans>Min price</Trans>}
                        htmlFor="min"
                        className="mr-4"
                    >
                        <FormInput
                            id="min"
                            className="max-w-lg"
                            errors={getErrorMessages('min')}
                            type="number"
                            min={0}
                            {...getNativeInputProps('min')}
                        />
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Max price</Trans>}
                        htmlFor="max"
                    >
                        <FormInput
                            id="max"
                            className="max-w-lg"
                            errors={getErrorMessages('max')}
                            type="number"
                            min={0}
                            {...getNativeInputProps('max')}
                        />
                    </FormGroup>
                </div>
                <FormGroup
                    label={<Trans>Blocked airlines</Trans>}
                    htmlFor="blockedAirlines"
                >
                    <FormInput
                        id="blockedAirlines"
                        className="max-w-lg"
                        errors={getErrorMessages('blockedAirlines')}
                        {...getNativeInputProps('blockedAirlines')}
                    />
                </FormGroup>
            </ModalBody>
            <div className="flex justify-end">
                <Button
                    className="mr-4"
                    variant="white"
                    onClick={onClose}
                    disabled={isLoading}
                >
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    onClick={onSubmit}
                    isLoading={isLoading}
                    disabled={isLoading}
                >
                    <Trans>Create</Trans>
                </Button>
            </div>
        </Modal>
    )
}

export default CreateForkModal
