import React from 'react'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormSelect from '../../utils/FormSelect'

const CreateGiftcardModal = ({
    formState,
    imageList,
}) => {
    const {
        getErrorMessages,
        getNativeInputProps,
    } = formState
    return (
        <>
            <div className="flex justify-between gap-4">
                <FormGroup
                    label={<Trans>Travelbooster code</Trans>}
                    htmlFor="code"
                >
                    <FormInput
                        id="code"
                        className="max-w-lg"
                        errors={getErrorMessages('code')}
                        type="number"
                        min={0}
                        {...getNativeInputProps('code')}
                    />
                </FormGroup>
                <FormGroup
                    label={<Trans>Paxfile ID</Trans>}
                    htmlFor="paxFileId"
                >
                    <FormInput
                        id="paxFileId"
                        className="max-w-lg"
                        errors={getErrorMessages('paxFileId')}
                        type="number"
                        min={100000}
                        {...getNativeInputProps('paxFileId')}
                    />
                </FormGroup>
            </div>
            <FormGroup
                label={<Trans>Amount</Trans>}
                htmlFor="amount"
            >
                <FormInput
                    id="amount"
                    className="max-w-lg"
                    errors={getErrorMessages('amount')}
                    type="number"
                    min={0}
                    {...getNativeInputProps('amount')}
                />
            </FormGroup>
            <div className="flex justify-between gap-4">
                <FormGroup
                    label={<Trans>From</Trans>}
                    htmlFor="from"
                >
                    <FormInput
                        id="from"
                        errors={getErrorMessages('from')}
                        {...getNativeInputProps('from')}
                    />
                </FormGroup>
                <FormGroup
                    label={<Trans>To</Trans>}
                    htmlFor="to"
                >
                    <FormInput
                        id="to"
                        errors={getErrorMessages('to')}
                        {...getNativeInputProps('to')}
                    />
                </FormGroup>
            </div>
            <FormGroup
                label={<Trans>Message</Trans>}
                htmlFor="message"
            >
                <FormInput
                    id="message"
                    as="textarea"
                    className="max-w-lg"
                    errors={getErrorMessages('message')}
                    {...getNativeInputProps('message')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Header</Trans>}
                htmlFor="headerImage"
            >
                <FormSelect
                    id="headerImage"
                    errors={getErrorMessages('headerImage')}
                    {...getNativeInputProps('headerImage')}
                >
                    {imageList.map(({ public_id: id, context }) => (
                        <option value={id} key={id}>
                            {context.custom.caption}
                        </option>
                    ))}
                </FormSelect>
            </FormGroup>
            <FormGroup
                label={<Trans>Footer</Trans>}
                htmlFor="footerImage"
            >
                <FormSelect
                    id="footerImage"
                    errors={getErrorMessages('footerImage')}
                    {...getNativeInputProps('footerImage')}
                >
                    {imageList.map(({ public_id: id, context }) => (
                        <option value={id} key={id}>
                            {context.custom.caption}
                        </option>
                    ))}
                </FormSelect>
            </FormGroup>
        </>
    )
}

export default CreateGiftcardModal
