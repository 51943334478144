import React from 'react'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const ForksPageTableRowActionButton = ({
    isLoading,
    canUpdate,
    canDelete,
    onUpdate,
    onDelete,
}) => (
    <DropdownMenu
        position="bottom-start"
        button={(
            <ActionButton as="span" disabled={isLoading} />
        )}
    >
        <>
            <DropdownMenuItem
                iconName="edit"
                disabled={isLoading || !canUpdate}
                onClick={onUpdate}
            >
                Update fork
            </DropdownMenuItem>
            <DropdownMenuItem
                iconName="bin"
                disabled={isLoading || !canDelete}
                onClick={onDelete}
            >
                Delete fork
            </DropdownMenuItem>
        </>
    </DropdownMenu>
)

export default ForksPageTableRowActionButton
