import { useMutation, gql } from '@apollo/client'

const MUTATION = gql`
    mutation createGiftcardPdf($input: CreateGiftcardPdfInput!) {
        createGiftcardPdf(input: $input) 
    }
`

const useCreateGiftcardPdfMutation = (options) => useMutation(MUTATION, options)

export default useCreateGiftcardPdfMutation
