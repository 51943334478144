/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { createBrowserHistory } from 'history'
import { i18n as linguiI18n } from '@lingui/core'
import { NotificationDispatcher } from '@connections/utils'
import * as storage from '../utils/storage'
import { loadAndValidateTokens } from '../utils/auth'
import enMessage from '../locales/en/messages'
import fetchInitialData from '../apollo/fetchInitialData'
import createApolloClient from '../apollo/createApolloClient'
import Notifications from './utils/Notifications'
import AppStateProvider from './providers/AppStateProvider'
import App from './App'
import AppProviders from './AppProviders'
import AppHead from './AppHead'

class AppWithState extends React.Component {
    constructor(props) {
        super(props)
        const {
            i18n = linguiI18n,
            history = createBrowserHistory(),
            apolloClient = createApolloClient(),
            notificationDispatcher = NotificationDispatcher.getInstance()
        } = props
        this.i18n = i18n
        this.history = history
        this.apolloClient = apolloClient
        this.notificationDispatcher = notificationDispatcher

        this.i18n.load('en', enMessage)
        this.i18n.activate('en')

        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.state = {
            isLoading: true,
            error: null,
            currentUser: null,
            login: this.login,
            logout: this.logout,
        }
    }

    async componentDidMount() {
        try {
            const { accessToken } = await loadAndValidateTokens()
            if (accessToken !== null) {
                await this.fetchInitialData()
            }
        } catch (e) {
            this.setState({ error: e })
        } finally {
            this.setState({ isLoading: false })
        }
    }

    async fetchInitialData() {
        const { apolloClient } = this
        const { currentUser } = await fetchInitialData(apolloClient)
        if (currentUser === null) {
            await this.logout()
            return
        }
        this.setState({ currentUser })
    }

    async login(accessToken, refreshToken, currentUser, redirectRoute = '/') {
        const { history } = this
        this.setState({ isLoading: true })
        await storage.setAccessToken(accessToken)
        await storage.setRefreshToken(refreshToken)
        await this.fetchInitialData()
        if (redirectRoute !== null) {
            history.push(redirectRoute)
        }
        this.setState({
            isLoading: false,
            currentUser,
        })
    }

    async logout() {
        const { apolloClient, history } = this
        this.setState({ isLoading: true })
        await storage.removeAccessToken()
        await storage.removeRefreshToken()
        await apolloClient.clearStore()
        history.push({ pathname: '/', search: '' })
        this.setState({
            isLoading: false,
            currentUser: null,
        })
    }

    render() {
        const {
            apolloClient,
            notificationDispatcher,
            history,
            i18n,
        } = this
        const {
            error,
            isLoading,
            currentUser,
        } = this.state
        return (
            <AppStateProvider value={this.state}>
                <AppProviders
                    apolloClient={apolloClient}
                    notificationDispatcher={notificationDispatcher}
                    i18n={i18n}
                >
                    <AppHead />
                    <App
                        error={error}
                        history={history}
                        isLoading={isLoading}
                        currentUser={currentUser}
                    />
                    <Notifications />
                </AppProviders>
            </AppStateProvider>
        )
    }
}

export default AppWithState
