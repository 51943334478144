import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'

const InvitationsPageTableHeaderRow = () => (
    <tr>
        <TableHeaderCell>
            <Trans>Email</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Role</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Status</Trans>
        </TableHeaderCell>
        <TableHeaderCell />
    </tr>
)

export default InvitationsPageTableHeaderRow
