import React from 'react'
import { Trans } from '@lingui/macro'
import Modal, { ModalTitle, ModalBody } from '../../utils/Modal'
import Button from '../../utils/Button'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'

const AddPaxFileIdToBookingModal = ({
    isOpen,
    onClose,
    onSubmit,
    paxFileId,
    isLoading,
    onChangePaxFileId,
}) => (
    <Modal
        isOpen={isOpen}
        onClose={onClose}
    >
        <ModalTitle
            title={<Trans>Add pax file id to booking</Trans>}
            onClose={onClose}
        />
        <ModalBody>
            <FormGroup
                label={<Trans>Pax file id</Trans>}
                htmlFor="paxFileId"
            >
                <FormInput
                    id="paxFileId"
                    value={paxFileId}
                    className="max-w-lg"
                    onChange={({ target }) => onChangePaxFileId(target.value)}
                />
            </FormGroup>
        </ModalBody>
        <div className="flex justify-end">
            <Button
                className="mr-4"
                variant="white"
                onClick={onClose}
                disabled={isLoading}
            >
                <Trans>Cancel</Trans>
            </Button>
            <Button
                onClick={onSubmit}
                disabled={isLoading}
                isLoading={isLoading}
            >
                <Trans>Add</Trans>
            </Button>
        </div>
    </Modal>
)

export default AddPaxFileIdToBookingModal
