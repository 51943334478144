import React from 'react'
import HydrateSafely from '../src/components/utils/HydrateSafely'
import AppWithState from '../src/components/AppWithState'

const Client = () => (
    <HydrateSafely>
        <AppWithState />
    </HydrateSafely>
)

export default Client
