import React from 'react'
import { Trans } from '@lingui/react'
import ActionButton from '../../utils/ActionButton'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'

const BookingsNoPaxFilePageTableRowActionButton = ({
    isLoading,
    onAddPaxFileId,
    onCreatePaxFileForBooking,
}) => (
    <DropdownMenu
        position="bottom-start"
        button={<ActionButton loading={isLoading} disabled={isLoading} />}
    >
        <DropdownMenuItem
            iconName="edit"
            onClick={onAddPaxFileId}
        >
            <Trans id="Add pax file id" />
        </DropdownMenuItem>
        <DropdownMenuItem
            disabled={isLoading}
            iconName="plus-circle"
            onClick={onCreatePaxFileForBooking}
        >
            <Trans id="Create pax file" />
        </DropdownMenuItem>
    </DropdownMenu>
)

export default BookingsNoPaxFilePageTableRowActionButton
