import React, { useState } from 'react'
import UpdateGiftcardModalWithState from './UpdateGiftcardModalWithState'
import GiftcardsPageTableRowActionButton from './GiftcardsPageTableRowActionButton'

const GiftcardsPageTableRowActionButtonWithState = ({
    giftcard,
    onUpdateCompleted,
    imageList,
}) => {
    const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)
    return (
        <>
            {isUpdateModalOpen && (
                <UpdateGiftcardModalWithState
                    giftcard={giftcard}
                    isOpen={isUpdateModalOpen}
                    onClose={() => setUpdateModalOpen(false)}
                    onCompleted={onUpdateCompleted}
                    imageList={imageList}
                />
            )}
            <GiftcardsPageTableRowActionButton
                onUpdate={() => setUpdateModalOpen(true)}
            />
        </>
    )
}

export default GiftcardsPageTableRowActionButtonWithState
