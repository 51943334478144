import React, { useState } from 'react'
import { delay } from '@connections/utils'
import useNotifications from '../../hooks/useNotifications'
import FlightSearchResultsPage from './FlightSearchResultsPage'
import useFlightSearchResultsPageQuery from './useFlightSearchResultsPageQuery'
import useSyncFlightSearchResultsMutation from './useSyncFlightSearchResultsMutation'

const FlightSearchResultsPageWithState = () => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [selectedAirlineCode, setSelectedAirlineCode] = useState('')
    const [selectedAirportCode, setSelectedAirportCode] = useState('')
    const {
        hasMore,
        refetch,
        isFetchingMore,
        handleFetchMore,
        flightSearchResults,
        flightSearchResultsAirlines,
        flightSearchResultsAirports,
    } = useFlightSearchResultsPageQuery(
        selectedAirlineCode,
        selectedAirportCode
    )
    const [
        syncFlightSearchResults,
        { loading: isSyncingFlightSearchResults },
    ] = useSyncFlightSearchResultsMutation()
    return (
        <FlightSearchResultsPage
            hasMore={hasMore}
            onFetchMore={handleFetchMore}
            isFetchingMore={isFetchingMore}
            flightSearchResults={flightSearchResults}
            flightSearchResultsAirlines={flightSearchResultsAirlines}
            selectedAirlineCode={selectedAirlineCode}
            onChangeSelectedAirlineCode={(code) => {
                setSelectedAirlineCode(code)
            }}
            flightSearchResultsAirports={flightSearchResultsAirports}
            selectedAirportCode={selectedAirportCode}
            onChangeSelectedAirportCode={(code) => {
                setSelectedAirportCode(code)
            }}
            onSyncFlightSearchResults={async () => {
                try {
                    await syncFlightSearchResults()
                    await delay(3000)
                    dispatchSuccess()
                    refetch()
                } catch (error) {
                    dispatchGraphqlError(error, {}, true)
                }
            }}
            onClearFilters={() => {
                setSelectedAirlineCode('')
                setSelectedAirportCode('')
            }}
            isSyncingFlightSearchResults={isSyncingFlightSearchResults}
        />
    )
}

export default FlightSearchResultsPageWithState
