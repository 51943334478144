import React from 'react'
import { Trans } from '@lingui/macro'
import LoginPageTitle from '../login/LoginPageTitle'
import LoginPageContainer from '../login/LoginPageContainer'

const AcceptInvitationPageContainer = ({
    invitation,
    children,
}) => {
    const {
        user,
        invitedBy,
    } = invitation
    return (
        <LoginPageContainer>
            <LoginPageTitle className="mb-4">
                {user !== null
                    ? <Trans>Accept invitation</Trans>
                    : <Trans>Create an account</Trans>}
            </LoginPageTitle>
            <div className="my-8">
                {invitedBy.firstName}
                {' '}
                {invitedBy.lastName}
                {' '}
                <Trans>
                    has invited you to collaborate on Connections POC
                </Trans>
            </div>
            {children}
        </LoginPageContainer>
    )
}

export default AcceptInvitationPageContainer
