import React from 'react'
import clsx from 'clsx'

const FormInputErrors = ({
    errors = []
}) => {
    if (errors.length === 0) {
        return null
    }
    return errors.map((error, index) => (
        <p
            key={error}
            data-testid="FormInputError"
            className={(clsx(
                'text-sm text-red-600',
                index === 0 && 'mt-1'
            ))}
        >
            {error}
        </p>
    ))
}

export default FormInputErrors
