import { gql, useQuery } from '@apollo/client'
import { STORYBLOK_CLIENT } from '@connections/constants'
import useSBFetchMore from '../../hooks/useSBFetchMore'

const QUERY = gql`
query ProgramsPageQuery($page: Int, $query: String) {
    TourItems(page: $page, sort_by: "name", filter_query_v2: {name: {like: $query}}) {
        items {
            full_slug
            uuid
            slug
            content {
                name
                price
                description
                body
            }
            translated_slugs {
                lang
                path
            }
        }
        total
    }
}

`

const useProgramsPageQuery = (query) => {
    const variables = {
        page: 1,
    }
    if (query !== '') {
        variables.query = `*${query}*`
    }
    const {
        data = {},
        error,
        loading,
        refetch,
        fetchMore,
    } = useQuery(QUERY, { variables, context: { client: STORYBLOK_CLIENT } })
    const {
        handleFetchMore,
        isFetchingMore,
        hasNextPage,
    } = useSBFetchMore(fetchMore, data, 'TourItems')

    return {
        error,
        loading,
        refetch,
        fetchMore,
        handleFetchMore,
        isFetchingMore,
        isFetching: loading,
        hasMore: !loading && !error && hasNextPage,
        programs: data?.TourItems?.items.filter(({ content }) => (
            content?.body?.find(({ component }) => (
                component === 'DayByDaySection'
                || component === 'DayByDayMapSection'
            ))
        )) || [],
    }
}

export default useProgramsPageQuery
