import React from 'react'
import clsx from 'clsx'

const FormGroup = ({
    label,
    htmlFor,
    className,
    children,
}) => (
    <div
        className={(clsx(
            'relative',
            className
        ))}
    >
        <label
            htmlFor={htmlFor}
            className={(clsx(
                'block text-sm font-medium leading-5 text-gray-700 mb-1',
            ))}
        >
            {label}
        </label>
        {children}
    </div>
)

export default FormGroup
