import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'
import Spinner from './Spinner'

const ActionButton = ({
    as = 'button',
    className,
    children,
    loading,
    disabled,
    onClick,
    ...props
}) => {
    const Component = as
    return (
        <Component
            className={(clsx(
                'inline-flex rounded-md p-1.5 focus:outline-none transition ease-in-out duration-150 hover:bg-gray-100 focus:bg-gray-100',
                className,
                loading && 'opacity-75',
                disabled && 'cursor-not-allowed',
            ))}
            {...props}
        >
            {(() => {
                if (loading) {
                    return <Spinner />
                }
                return (
                    <Icon
                        className="group-hover:text-gray-500 group-focus:text-gray-500"
                        name="menu-dots"
                    />
                )
            })()}
        </Component>
    )
}

export default ActionButton
