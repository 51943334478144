/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import clsx from 'clsx'
import Fade from './Fade'

const PageContent = ({
    children,
    className,
    size = 'default',
    ...props
}) => (
    <main
        tabIndex="0"
        className={clsx(
            'flex-1 relative z-0 overflow-y-scroll focus:outline-none h-full',
            className,
        )}
        {...props}
    >
        <div
            className={clsx(
                'mx-auto px-4 pb-48',
                size === 'full' && 'w-100',
                size === 'default' && 'max-w-7xl',
                size === 'small' && 'max-w-3xl',
            )}
        >
            <Fade>
                {children}
            </Fade>
        </div>
    </main>
)

export default PageContent
