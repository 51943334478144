import { useMutation, gql } from '@apollo/client'

export const SYNC_FLIGHT_SEARCH_RESULTS_MUTATION = gql`
    mutation syncFlightSearchResults {
        syncFlightSearchResults
    }
`

const useSyncFlightSearchResultsMutation = (options) => useMutation(SYNC_FLIGHT_SEARCH_RESULTS_MUTATION, options)

export default useSyncFlightSearchResultsMutation
