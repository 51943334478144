import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import InvitationsPageTableRowActionButton from './InvitationsPageTableRowActionButton'
import usePermissions from '../../hooks/usePermissions'
import useNotifications from '../../hooks/useNotifications'
import useResendInvitationMutation from './useResendInvitationMutation'
import DeleteInvitationModalWithState from './DeleteInvitationModalWithState'

const InvitationsPageTableRowActionButtonWithState = ({
    invitation,
    onDeleteUserSuccess,
    onResendInvitationSuccess,
}) => {
    const {
        canResendInvitation,
        canDeleteInvitation,
    } = usePermissions()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [resendInvitation, { loading }] = useResendInvitationMutation()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    return (
        <>
            <InvitationsPageTableRowActionButton
                isLoading={loading}
                invitation={invitation}
                canResendInvitation={canResendInvitation}
                canDeleteInvitation={canDeleteInvitation}
                onDeleteInvitation={() => setIsDeleteModalOpen(true)}
                onResendInvitation={async () => {
                    try {
                        const variables = { invitationId: invitation.id }
                        await resendInvitation({ variables })
                        onResendInvitationSuccess()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Invite send to <strong>{invitation.email}</strong>.
                                </Trans>
                            )
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }}
            />
            {isDeleteModalOpen && (
                <DeleteInvitationModalWithState
                    isOpen
                    invitation={invitation}
                    onCompleted={onDeleteUserSuccess}
                    onClose={() => setIsDeleteModalOpen(false)}
                />
            )}
        </>
    )
}

export default InvitationsPageTableRowActionButtonWithState
