import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'
import FormInputErrors from './FormInputErrors'

const FormSelect = ({
    id,
    errors,
    hasError,
    iconNameBefore,
    iconNameAfter,
    className,
    selectClassName,
    ...props
}) => (
    <>
        <div
            className={(clsx(
                'rounded-md shadow-sm relative',
                className
            ))}
        >
            {iconNameBefore && (
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Icon
                        size="small"
                        name={iconNameBefore}
                    />
                </div>
            )}
            <select
                id={id}
                className={(clsx(
                    'mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 pl-3 pr-9 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm',
                    hasError && 'border-red-300 text-red-600 placeholder-red-300 focus:border-red-300 focus:ring-red',
                    iconNameBefore && 'pl-9',
                    (iconNameAfter || hasError) && 'pr-15',
                    selectClassName,
                ))}
                {...props}
            />
            {(iconNameAfter || hasError) && (
                <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
                    <Icon
                        size="small"
                        name={iconNameAfter || 'warning-circle'}
                        className={(clsx(
                            hasError && 'text-red-500',
                        ))}
                    />
                </div>
            )}
        </div>
        <FormInputErrors errors={errors} />
    </>
)

export default FormSelect
