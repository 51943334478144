import React from 'react'
import { Trans } from '@lingui/macro'
import { isRequired } from '@connections/utils'
import useFormState, { createFormValidation } from 'use-form-state'

const validation = createFormValidation([{
    path: 'roleId',
    validate: isRequired,
    message: <Trans>Please select a role.</Trans>,
}])

const toUpdateInput = (values) => ({
    ...values,
    roleId: parseInt(values.roleId, 10),
})

const useUpdateUserFormState = (initialValues, options = {}) => (
    useFormState(initialValues, {
        validation,
        valuesToInput: toUpdateInput,
        ...options
    })
)

export default useUpdateUserFormState
