import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const DELETE_INVITATION_MUTATION = gql`
    mutation deleteInvitation($invitationId: String!) {
        deleteInvitation(invitationId: $invitationId)
    }
`

const useDeleteInvitationMutation = (options) => (
    useMutation(DELETE_INVITATION_MUTATION, options)
)

export default useDeleteInvitationMutation
