import { useMutation, gql } from '@apollo/client'

export const ADD_PAX_FILE_ID_TO_BOOKING_MUTATION = gql`
    mutation addPaxFileIdToBookingMutation ($bookingUid: ID!, $paxFileId: String!) {
        addPaxFileIdToBooking(bookingUid: $bookingUid, paxFileId: $paxFileId) {
            id
            data
        }
    }
`

const useAddPaxFileIdToBookingMutation = () => useMutation(ADD_PAX_FILE_ID_TO_BOOKING_MUTATION, { refetchQueries: ['bookingsPageQuery'] })

export default useAddPaxFileIdToBookingMutation
