import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteUserModal from './DeleteUserModal'
import useDeleteInvitationMutation from './useDeleteUserMutation'

const DeleteUserModalWithState = ({
    user,
    isOpen,
    onClose,
    onCompleted,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteUser, { loading }] = useDeleteInvitationMutation({ onCompleted })
    return (
        <DeleteUserModal
            user={user}
            isOpen={isOpen}
            onClose={onClose}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { userId: user.id }
                    await deleteUser({ variables })
                    dispatchSuccess({
                        message: <Trans>{user.email} successfully deleted.</Trans>
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                } finally {
                    onClose()
                }
            }}
        />
    )
}

export default DeleteUserModalWithState
