import React from 'react'
import Spinner from './Spinner'

const PageLoading = () => (
    <div className="flex h-screen justify-center items-center">
        <Spinner size="large" />
    </div>
)

export default PageLoading
