import * as constants from '@connections/constants'
import useAppState from './useAppState'

const {
    USER_TYPE_ADMIN: ADMIN,
} = constants

const usePermissions = () => {
    const { currentUser: { type } } = useAppState()

    const canDeleteUser = type === ADMIN
    const canChangeUserRole = type === ADMIN
    const canCreateInvitation = type === ADMIN
    const canResendInvitation = type === ADMIN
    const canDeleteInvitation = type === ADMIN

    return {
        canDeleteUser,
        canChangeUserRole,
        canCreateInvitation,
        canResendInvitation,
        canDeleteInvitation,
    }
}

export default usePermissions
