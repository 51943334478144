import React from 'react'
import useNotifications from '../../hooks/useNotifications'
import { INVITATIONS_PAGE_QUERY } from '../users-invitations/useInvitationsPageQuery'
import CreateInvitationModal from './CreateInvitationModal'
import useCreateUserFormState from './useCreateInvitationFormState'
import useCreateInvitationMutation from './useCreateInvitationMutation'
import useCreateInvitationModalQuery from './useCreateInvitationModalQuery'

const CreateInvitationModalWithState = ({
    isOpen,
    onClose,
}) => {
    const formState = useCreateUserFormState()
    const { roles } = useCreateInvitationModalQuery()
    const [createInvitation, { loading }] = useCreateInvitationMutation()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateInvitationModal
            isOpen={isOpen}
            onClose={() => {
                onClose()
                formState.resetForm()
            }}
            isCreatingInvitation={loading}
            formState={formState}
            roles={roles}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { roleId, email } = formState.values
                        const variables = {
                            input: {
                                email: email.toLowerCase().trim(),
                                roleId: parseInt(roleId, 10),
                            }
                        }
                        await createInvitation({
                            variables,
                            refetchQueries: [{
                                query: INVITATIONS_PAGE_QUERY,
                            }]
                        })
                        dispatchSuccess()
                        onClose()
                    } catch (error) {
                        dispatchGraphqlError(error, {}, true)
                    }
                }
            }}
        />
    )
}

export default CreateInvitationModalWithState
