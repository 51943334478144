import React from 'react'
import clsx from 'clsx'
import { Menu } from '@headlessui/react'
import Icon from './Icon'

const DropdownMenuItem = ({
    disabled,
    iconName,
    iconClassName,
    isLoading,
    className,
    children,
    ...props
}) => (
    <Menu.Item as="li">
        {({ active }) => (
            <button
                type="button"
                disabled={disabled}
                className={clsx(
                    'group flex items-center w-full px-5 py-2 text-sm leading-4 focus:outline-none',
                    !active && 'text-gray-500',
                    active && 'bg-gray-50 text-gray-900',
                    disabled && 'cursor-not-allowed opacity-50',
                    className,
                )}
                {...props}
            >
                {iconName && (
                    <Icon
                        name={isLoading ? 'spinner' : iconName}
                        className={(clsx(
                            'mr-3',
                            !active && 'text-gray-400',
                            active && 'text-primary',
                            isLoading && 'animate-spin',
                            iconClassName,
                        ))}
                    />
                )}
                {children}
            </button>
        )}
    </Menu.Item>
)

export default DropdownMenuItem
