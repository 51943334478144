import { useMutation, gql } from '@apollo/client'

export const CREATE_INVITATION_MUTATION = gql`
    mutation createInvitationMutation ($input: CreateInvitationInput!) {
        createInvitation(input: $input) {
            invitation {
                id
                email
            }
        }
    }
`

const useCreateInvitationMutation = () => useMutation(CREATE_INVITATION_MUTATION)

export default useCreateInvitationMutation
