import useFormState from 'use-form-state'
import { validation } from './useCreateForkFormState'

const useUpdateForkFormState = ({
    code,
    min,
    max,
    blockedAirlines,
}) => (
    useFormState({
        code,
        min,
        max,
        blockedAirlines,
    }, {
        validation,
    })
)

export default useUpdateForkFormState
