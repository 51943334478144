import React from 'react'
import { format } from 'date-fns'
import { gql } from '@apollo/client'
import TableCell from '../../utils/TableCell'
import GiftcardsPageTableRowActionButtonWithState from './GiftcardsPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment GiftcardsPageTableRow on GiftcardBookingItem {
        id
        data
        booking {
            id
            uid
            data
            status
        }
    }
`

const GiftcardsPageTableRow = ({
    giftcard,
    onDownloadPdf,
    onUpdateCompleted,
    imageList,
    isCreatingGiftcardPdf,
}) => {
    const {
        data: {
            buyer,
            recipient,
            value,
            giftcard: {
                GiftCardCode: code,
                IssueDate: issueDate,
            }
        },
        booking: {
            data: bookingData,
        },
    } = giftcard
    return (
        <tr data-testid="GiftcardsPageTableRow">
            <TableCell hasText>
                {format(new Date(issueDate), 'dd/MM/yyyy')}
            </TableCell>
            <TableCell hasText>
                {code}
            </TableCell>
            <TableCell hasText>
                {bookingData?.paxFile?.ID}
            </TableCell>
            <TableCell hasText>
                {buyer}
            </TableCell>
            <TableCell hasText>
                {recipient}
            </TableCell>
            <TableCell hasText>
                €{value}
            </TableCell>
            <TableCell>
                <div className="inline-flex items-center  bg-primary rounded-md overflow-hidden text-white">
                    <button
                        type="button"
                        onClick={() => onDownloadPdf(giftcard, 'nl')}
                        className="hover:bg-primary-700 py-1 px-2 disabled:bg-primary-300 disabled:cursor-not-allowed"
                        disabled={isCreatingGiftcardPdf}
                    >
                        NL
                    </button>
                    <button
                        type="button"
                        onClick={() => onDownloadPdf(giftcard, 'fr')}
                        className="hover:bg-primary-700 py-1 px-2 disabled:bg-primary-300 disabled:cursor-not-allowed"
                        disabled={isCreatingGiftcardPdf}
                    >
                        FR
                    </button>
                    <button
                        type="button"
                        onClick={() => onDownloadPdf(giftcard, 'en')}
                        className="hover:bg-primary-700 py-1 px-2 disabled:bg-primary-300 disabled:cursor-not-allowed"
                        disabled={isCreatingGiftcardPdf}
                    >
                        EN
                    </button>
                </div>
            </TableCell>
            <TableCell>
                <GiftcardsPageTableRowActionButtonWithState
                    giftcard={giftcard}
                    onUpdateCompleted={onUpdateCompleted}
                    imageList={imageList}
                />
            </TableCell>
        </tr>
    )
}

GiftcardsPageTableRow.FRAGMENT = FRAGMENT

export default GiftcardsPageTableRow
