import { gql } from '@apollo/client'

const CurrentUserFragment = gql`
    fragment CurrentUser on CurrentUser {
        id
        type
        firstName
        lastName
        fullName
        email
        role
    }
`

export default CurrentUserFragment
