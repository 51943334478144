import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '@connections/constants'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import ForksPageTableRowActionButton from './ForksPageTableRowActionButton'
import DeleteForkModalWithState from './DeleteForkModalWithState'
import UpdateForkModalWithState from './UpdateForkModalWithState'
// import useResendUserInviteMutation from './useResendUserInviteMutation'

const { ADMIN_ROLE_OWNER: OWNER } = constants

const ForksPageTableRowActionButtonWithState = ({
    fork,
    onDeleteCompleted,
    onUpdateCompleted,
}) => {
    const { currentUser } = useAppState()
    const { dispatchSuccess, dispatchError } = useNotifications()
    // const [resendInvite, { loading }] = useResendUserInviteMutation()
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)
    return (
        <>
            <DeleteForkModalWithState
                fork={fork}
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onCompleted={onDeleteCompleted}
            />
            <UpdateForkModalWithState
                fork={fork}
                isOpen={isUpdateModalOpen}
                onClose={() => setUpdateModalOpen(false)}
                onCompleted={onUpdateCompleted}
            />
            <ForksPageTableRowActionButton
                isLoading={false}
                canDelete={currentUser.role === OWNER}
                canUpdate={currentUser.role === OWNER}
                onDelete={() => setDeleteModalOpen(true)}
                onUpdate={() => setUpdateModalOpen(true)}
                onResendInvitation={async () => {
                    try {
                        const variables = { invitationId: invitation.id }
                        await resendInvite({ variables })
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Invite send to <strong>{invitation.email}</strong>.
                                </Trans>
                            )
                        })
                        if (typeof onResendInvitationSuccess === 'function') {
                            onResendInvitationSuccess()
                        }
                    } catch (error) {
                        const { message } = error.graphQLErrors[0] || {}
                        dispatchError({ message })
                    }
                }}
            />
        </>
    )
}

export default ForksPageTableRowActionButtonWithState
