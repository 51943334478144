import React from 'react'
import { format } from 'date-fns'
import { gql } from '@apollo/client'
import { Trans } from '@lingui/react'
import TableCell from '../../utils/TableCell'
import BookingsNoPaxFilePageTableRowActionButtonWithState from '../bookings-no-pax-file/BookingsNoPaxFilePageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment BookingsPageTableRow on Booking {
        id
        uid
        data
        createdAt
        contact {
            id
            lastName
            firstName
        }
        travelers {
            id
        }
    }
`

const BookingsPageTableRow = ({
    booking,
}) => {
    const {
        uid,
        data,
        contact,
        travelers,
        createdAt,
    } = booking
    const paxFileId = data?.reservationCompleted?.PaxFileId
    const pnr = data?.reservationCompleted?.ReservationItems?.[0]?.PassengerNameRecord

    return (
        <tr data-testid="BookingsPageTableRow">
            <TableCell hasText>
                {format(new Date(parseInt(createdAt, 10)), 'dd/MM/yyyy')}
            </TableCell>
            <TableCell hasText>
                {`${contact.firstName} ${contact.lastName}`}
            </TableCell>
            <TableCell hasText>
                {data.startDate ? format(new Date(data.startDate), 'dd/MM/yyyy') : null}
            </TableCell>
            <TableCell hasText>
                {travelers.length}
            </TableCell>
            <TableCell hasText>
                {pnr}
            </TableCell>
            <TableCell hasText>
                {paxFileId || <Trans id="No" />}
            </TableCell>
            <TableCell hasText className="text-right">
                {paxFileId ? null : (
                    <BookingsNoPaxFilePageTableRowActionButtonWithState bookingUid={uid} />
                )}
            </TableCell>
        </tr>
    )
}

BookingsPageTableRow.FRAGMENT = FRAGMENT

export default BookingsPageTableRow
