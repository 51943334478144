/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const PageError = ({
    error
}) => (
    <div className="bg-white h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
            <main className="sm:flex">
                <p className="text-4xl font-extrabold text-primary sm:text-5xl">500</p>
                <div className="sm:ml-6">
                    <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                        <h1 className="text-4xl font-semibold text-gray-900 tracking-tight sm:text-5xl">Something went wrong</h1>
                        <p className="mt-2 text-base text-gray-500">
                            {(error
                                ? error.message
                                : 'Try refreshing the page. If the problem persists please contact support.'
                            )}
                        </p>
                    </div>
                    <div className="mt-8 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                        <a href="/" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                            Go back home
                        </a>
                    </div>
                </div>
            </main>
        </div>
    </div>
)

export default PageError
