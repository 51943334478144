import React from 'react'
import { Trans } from '@lingui/macro'
import { isValidEmail, isRequired } from '@connections/utils'
import useFormState, { createFormValidation } from 'use-form-state'

const validation = createFormValidation([{
    path: 'email',
    validate: (email) => (!isRequired(email) && !isValidEmail(email)) || isValidEmail(email),
    message: <Trans>Please fill in a valid email address.</Trans>,
}, {
    path: 'roleId',
    validate: isRequired,
    message: <Trans>Please select a role.</Trans>,
}])

const useCreateUserFormState = () => (
    useFormState({
        email: '',
        roleId: '',
    }, {
        validation,
    })
)

export default useCreateUserFormState
