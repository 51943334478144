import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation deleteCheapestPriceFork($id: Int!) {
        deleteCheapestPriceFork(id: $id)
    }
`

const useDeleteForkMutation = (options) => (
    useMutation(MUTATION, options)
)

export default useDeleteForkMutation
