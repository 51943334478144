import React from 'react'
import Table from '../../utils/Table'
import InfiniteScroll from '../../utils/InfiniteScroll'
import UserInvitationsPageTableRow from './InvitationsPageTableRow'
import UserInvitationsPageTableHeaderRow from './InvitationsPageTableHeaderRow'

const InvitationsPageTable = ({
    hasMore,
    invitations,
    onFetchMore,
    isFetchingMore,
    onDeleteUserSuccess,
    onResendInvitationSuccess,
}) => (
    <InfiniteScroll
        hasMore={hasMore}
        onFetchMore={onFetchMore}
        isFetchingMore={isFetchingMore}
    >
        <Table data-testid="InvitationsPageTable">
            <thead>
                <UserInvitationsPageTableHeaderRow />
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {invitations.map((invitation) => (
                    <UserInvitationsPageTableRow
                        key={invitation.id}
                        invitation={invitation}
                        onDeleteUserSuccess={onDeleteUserSuccess}
                        onResendInvitationSuccess={onResendInvitationSuccess}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default InvitationsPageTable
