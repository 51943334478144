import React from 'react'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import RegisterPage from './RegisterPage'
import useRegisterMutation from './useRegisterMutation'
import useRegisterFormState from './useRegisterFormState'

const RegisterPageWithState = ({
    token,
    invitation,
}) => {
    const { login } = useAppState()
    const formState = useRegisterFormState()
    const [register, { loading }] = useRegisterMutation()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <RegisterPage
            email={invitation.email}
            invitation={invitation}
            formState={formState}
            isRegistering={loading}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const variables = {
                            invitationId: invitation.id,
                            input: {
                                token,
                                ...formState.values,
                            }
                        }
                        const { data } = await register({ variables })
                        const {
                            accessToken,
                            refreshToken,
                            currentUser,
                        } = data.register
                        await login(accessToken, refreshToken, currentUser, { pathname: '/', search: '' })
                        dispatchSuccess()
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default RegisterPageWithState
