import { useMutation, gql } from '@apollo/client'

const MUTATION = gql`
    mutation createCheapestPriceFork ($input: CheapestPriceForkInput!) {
        createCheapestPriceFork(input: $input) {
            cheapestPriceFork {
                id
                code
                min
                max
                blockedAirlines
            }
        }
    }
`

const useCreateForkMutation = (options) => useMutation(MUTATION, options)

export default useCreateForkMutation
