import React, { useState } from 'react'
import useAppState from '../../hooks/useAppState'
import LoginPage from './LoginPage'
import useLoginMutation from './useLoginMutation'
import useLoginFormState from './useLoginFormState'

const LoginPageWithState = ({
    redirectRoute,
}) => {
    const appState = useAppState()
    const [login, { loading }] = useLoginMutation()
    const formState = useLoginFormState()
    const [errorMessage, setErrorMessage] = useState(null)
    return (
        <LoginPage
            errorMessage={errorMessage}
            formState={formState}
            isLoading={loading}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { email, password } = formState.values
                        const input = { email, password }
                        const { data } = await login({ variables: { input } })
                        const {
                            accessToken,
                            refreshToken,
                            currentUser,
                        } = data.loginWithPassword
                        appState.login(accessToken, refreshToken, currentUser, redirectRoute)
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        setErrorMessage(message)
                    }
                }
            }}
        />
    )
}

export default LoginPageWithState
