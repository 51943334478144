import React, { forwardRef } from 'react'
import clsx from 'clsx'

const Tag = forwardRef(({
    className,
    children,
    ...props
}, ref) => (
    <span
        ref={ref}
        className={
            clsx(
                'uppercase font-medium rounded-md py-[2px] px-1 text-xs',
                className,
            )
        }
        {...props}
    >
        {children}
    </span>
))

export default Tag
