import { useQuery, gql } from '@apollo/client'
import PageInfo from '../../../apollo/PageInfoFragment'
import { connectionToCollection } from '../../../utils/graphql'
import useFetchMore from '../../hooks/useFetchMore'
import UsersPageTableRow from './UsersPageTableRow'

export const USERS_PAGE_QUERY = gql`
    query usersPageQuery($after: String, $first: Int) {
        users(after: $after, first: $first) {
            edges {
                cursor
                node {
                    ...UsersPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PageInfo}
    ${UsersPageTableRow.FRAGMENT}
`

const useUsersPageQuery = () => {
    const {
        data = {},
        error,
        loading,
        fetchMore,
        refetch,
    } = useQuery(USERS_PAGE_QUERY)
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(fetchMore, data, 'users')
    return {
        error,
        isFetching: loading,
        refetch,
        users: connectionToCollection(data.users),
        handleFetchMore,
        isFetchingMore,
        hasMore: !loading && !error && hasNextPage,
    }
}

export default useUsersPageQuery
