import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteForkModal from './DeleteForkModal'
import useDeleteForkMutation from './useDeleteForkMutation'

const DeleteForkModalWithState = ({
    fork,
    isOpen,
    onClose,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [deleteAudit, { loading }] = useDeleteForkMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteForkModal
            fork={fork}
            isOpen={isOpen}
            isLoading={loading}
            onClose={onClose}
            onConfirm={async () => {
                try {
                    await deleteAudit({ variables: { id: fork.id } })
                    onClose()
                    dispatchSuccess({
                        message: <Trans>Fork with code {fork.code} successfully deleted.</Trans>
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default DeleteForkModalWithState
