import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'

const GiftcardsPageTableHeaderRow = () => (
    <tr>
        <TableHeaderCell>
            <Trans>Date created</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Code</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Paxfile</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>From</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>To</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Amount</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Download</Trans>
        </TableHeaderCell>
        <TableHeaderCell />
    </tr>
)

export default GiftcardsPageTableHeaderRow
