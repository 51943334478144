import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import { Link, useRouteMatch } from 'react-router-dom'
import useAppState from '../hooks/useAppState'
import Icon from './Icon'
import Avatar from './Avatar'
import DropdownMenu from './DropdownMenu'
import DropdownMenuItem from './DropdownMenuItem'
import Logo from './Logo'
import useRoles from '../hooks/useRoles'

const PageNavigationItem = ({
    to,
    matchPath,
    exact = false,
    iconName,
    children,
    className,
}) => {
    const isActive = useRouteMatch({
        path: matchPath || to,
        exact,
    }) !== null
    const ariaCurrent = isActive ? 'page' : ''
    return (
        <Link
            to={to}
            aria-current={ariaCurrent}
            className={(clsx(
                'group inline-flex items-center px-2 pt-1 border-b-2 text-sm font-medium',
                !isActive && 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                isActive && 'border-primary text-black',
                className,
            ))}
        >
            <Icon
                name={iconName}
                className={clsx(
                    'mr-3',
                    !isActive && 'group-hover:text-gray-500',
                    isActive && 'text-red-600',
                )}
            />
            {children}
        </Link>
    )
}

const PageNavigation = () => {
    const { logout } = useAppState()
    const { isAdmin } = useRoles()

    return (
        <nav className="bg-white border-b border-gray-200">
            <div className="max-w-7xl mx-auto px-4">
                <div className="flex justify-between h-16">
                    <div className="flex">
                        <div className="flex-shrink-0 flex items-center">
                            <Logo className="block h-10 w-auto" />
                        </div>
                        <div className="hidden sm:-my-px sm:ml-10 sm:flex sm:space-x-8">
                            {isAdmin && (
                                <>
                                    <PageNavigationItem to="/bookings" iconName="file">
                                        <Trans>Bookings</Trans>
                                    </PageNavigationItem>
                                    <PageNavigationItem to="/flight-search-results" iconName="search">
                                        <Trans>Search results</Trans>
                                    </PageNavigationItem>
                                    <PageNavigationItem to="/forks" iconName="dollar-shield">
                                        <Trans>Forks</Trans>
                                    </PageNavigationItem>
                                    <PageNavigationItem to="/users" iconName="users">
                                        <Trans>Users</Trans>
                                    </PageNavigationItem>
                                </>
                            )}
                            <PageNavigationItem to="/programs" iconName="globe">
                                <Trans>Programs</Trans>
                            </PageNavigationItem>
                            <PageNavigationItem to="/giftcards" iconName="gift">
                                <Trans>Gift cards</Trans>
                            </PageNavigationItem>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <DropdownMenu
                            buttonClassName="flex rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            button={(
                                <Avatar name="user" />
                            )}
                        >
                            {isAdmin && (
                                <>
                                    <DropdownMenuItem
                                        iconName="globe"
                                        onClick={() => window.open(process.env.NEXT_PUBLIC_TRAVEL_BOOSTER_URL, '_blank')}
                                    >
                                        <Trans>Travel booster</Trans>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                        iconName="search"
                                        onClick={() => window.open(process.env.NEXT_PUBLIC_APP_SEARCH_URL, '_blank')}
                                    >
                                        <Trans>Search engine</Trans>
                                    </DropdownMenuItem>
                                </>
                            )}
                            <DropdownMenuItem
                                iconName="logout"
                                onClick={() => logout()}
                            >
                                <Trans>Logout</Trans>
                            </DropdownMenuItem>
                        </DropdownMenu>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default PageNavigation
