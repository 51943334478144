import React from 'react'
import Table from '../../utils/Table'
import GiftcardsPageTableRow from './GiftcardsPageTableRow'
import GiftcardsPageTableHeaderRow from './GiftcardsPageTableHeaderRow'

const GiftcardsPageTable = ({
    giftcards,
    onDownloadPdf,
    onUpdateCompleted,
    imageList,
    isCreatingGiftcardPdf,
}) => (
    <Table data-testid="GiftcardsPageTable">
        <thead>
            <GiftcardsPageTableHeaderRow />
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
            {giftcards.map((giftcard) => (
                <GiftcardsPageTableRow
                    key={giftcard.id}
                    giftcard={giftcard}
                    onDownloadPdf={onDownloadPdf}
                    onUpdateCompleted={onUpdateCompleted}
                    imageList={imageList}
                    isCreatingGiftcardPdf={isCreatingGiftcardPdf}
                />
            ))}
        </tbody>
    </Table>
)

export default GiftcardsPageTable
