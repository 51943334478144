import React from 'react'
import * as constants from '@connections/constants'
import BookingsPage from '../bookings/BookingsPage'
import useBookingsPageQuery from '../bookings/useBookingsPageQuery'

const {
    BOOKING_TYPE_FLIGHT: FLIGHT,
    BOOKING_STATUS_COMPLETED: COMPLETED,
} = constants

const BookingsNoPaxFilePageWithState = () => {
    const {
        hasMore,
        bookings,
        isFetching,
        isFetchingMore,
        handleFetchMore,
    } = useBookingsPageQuery({
        type: FLIGHT,
        hasPaxFile: false,
        status: COMPLETED,
    })

    return (
        <BookingsPage
            hasMore={hasMore}
            bookings={bookings}
            isFetching={isFetching}
            onFetchMore={handleFetchMore}
            isFetchingMore={isFetchingMore}
        />
    )
}

export default BookingsNoPaxFilePageWithState
