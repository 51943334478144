import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const RESEND_INVITATION_MUTATION = gql`
    mutation resendInvitation($invitationId: String!) {
        resendInvitation(invitationId: $invitationId) {
            invitation {
                id
                status
            }
        }
    }
`

const useResendInvitationMutation = () => useMutation(RESEND_INVITATION_MUTATION)

export default useResendInvitationMutation
