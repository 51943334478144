import { gql } from '@apollo/client'
import CurrentUser from './CurrentUserFragment'

export const INITIAL_DATA_QUERY = gql`
    query {
        currentUser {
            ...CurrentUser
        }
    }
    ${CurrentUser}
`

export default async function fetchInitialData(apollo, options) {
    const query = INITIAL_DATA_QUERY
    const response = await apollo.query({ query, ...options })
    return response.data
}
