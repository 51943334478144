import React from 'react'
import { gql } from '@apollo/client'
import { Trans } from '@lingui/macro'
import TableCell from '../../utils/TableCell'
import { formatGraphQlPrice, formatGraphQlDateTimeString } from '../../../utils/formatting'

const FRAGMENT = gql`
    fragment FlightSearchResultsPageTableRow on FlightSearchResult {
        id
        price
        airlineCode
        provider
        returnDate
        arrivalCode
        directFlight
        departureCode
        departureDate
    }
`

const FlightSearchResultsPageTableRow = ({
    flightSearchResult,
}) => {
    const {
        price,
        airlineCode,
        provider,
        returnDate,
        arrivalCode,
        directFlight,
        departureCode,
        departureDate,
    } = flightSearchResult

    return (
        <tr>
            <TableCell>
                {departureCode}
            </TableCell>
            <TableCell>
                {arrivalCode}
            </TableCell>
            <TableCell>
                {formatGraphQlDateTimeString(departureDate)}
            </TableCell>
            <TableCell>
                {formatGraphQlDateTimeString(returnDate)}
            </TableCell>
            <TableCell>
                {formatGraphQlPrice(price)}
            </TableCell>
            <TableCell>
                {airlineCode}
            </TableCell>
            <TableCell>
                {provider}
            </TableCell>
            <TableCell>
                {directFlight
                    ? <Trans>Yes</Trans>
                    : <Trans>No</Trans>}
            </TableCell>
        </tr>
    )
}

FlightSearchResultsPageTableRow.FRAGMENT = FRAGMENT

export default FlightSearchResultsPageTableRow
