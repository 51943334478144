import { gql } from '@apollo/client'

const PageInfoFragment = gql`
    fragment PageInfo on PageInfo {
        hasNextPage
        startCursor
        endCursor
    }
`

export default PageInfoFragment
