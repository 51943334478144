import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../utils/PageContainer'
import PageNavigation from '../../utils/PageNavigation'
import EmptyTableCard from '../../utils/EmptyTableCard'
import UsersPageHeaderWithState from '../users/UsersPageHeaderWithState'
import InvitationsPageTable from './InvitationsPageTable'
import TableLoading from '../../utils/TableLoading'

const InvitationsPage = ({
    hasMore,
    isFetching,
    invitations,
    onFetchMore,
    onInviteUser,
    isFetchingMore,
    canCreateInvitation,
    onDeleteUserSuccess,
    onResendInvitationSuccess,
}) => (
    <PageContainer>
        <PageNavigation />
        <PageContent>
            <UsersPageHeaderWithState />
            {(() => {
                if (isFetching) {
                    return (
                        <TableLoading />
                    )
                }
                if (invitations.length <= 0) {
                    return (
                        <EmptyTableCard
                            iconName="send"
                            title={<Trans>No invitations pending</Trans>}
                            description={<Trans>No invitations where found.</Trans>}
                        >
                            {canCreateInvitation && (
                                <Button
                                    className="mt-6"
                                    onClick={onInviteUser}
                                >
                                    <Trans>Invite user</Trans>
                                </Button>
                            )}
                        </EmptyTableCard>
                    )
                }
                return (
                    <InvitationsPageTable
                        hasMore={hasMore}
                        invitations={invitations}
                        onFetchMore={onFetchMore}
                        isFetchingMore={isFetchingMore}
                        onDeleteUserSuccess={onDeleteUserSuccess}
                        onResendInvitationSuccess={onResendInvitationSuccess}
                    />
                )
            })()}
        </PageContent>
    </PageContainer>
)

export default InvitationsPage
