import { gql, useQuery } from '@apollo/client'
import PageInfo from '../../../apollo/PageInfoFragment'
import { connectionToCollection } from '../../../utils/graphql'
import useFetchMore from '../../hooks/useFetchMore'
import BookingsPageTableRow from './BookingsPageTableRow'

const QUERY = gql`
    query bookingsPageQuery($after: String, $first: Int, $where: BookingWhereInput) {
        bookings(after: $after, first: $first, where: $where) {
            edges {
                cursor
                node {
                    ...BookingsPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PageInfo}
    ${BookingsPageTableRow.FRAGMENT}
`

const useBookingsPageQuery = (where) => {
    const variables = {
        where,
    }
    const {
        error,
        loading,
        fetchMore,
        data = {},
    } = useQuery(QUERY, { variables, fetchPolicy: 'network-only' })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(fetchMore, data, 'bookings')

    return {
        isFetchingMore,
        handleFetchMore,
        isFetching: loading,
        hasMore: !loading && !error && hasNextPage,
        bookings: connectionToCollection(data?.bookings),
    }
}

export default useBookingsPageQuery
