import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const Avatar = ({
    className,
    imageUrl = null,
    iconName = 'user',
    iconClassName,
    iconProps,
    ...props
}) => (
    <span
        className={clsx(
            'inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100 p-2 bg-cover',
            className,
        )}
        style={{ backgroundImage: `url(${imageUrl})` }}
        {...props}
    >
        {imageUrl === null && (
            <Icon
                name={iconName}
                className={clsx(
                    'h-full w-full',
                    iconClassName,
                )}
                {...iconProps}
            />
        )}
    </span>
)

export default Avatar
