import React from 'react'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import PageTitle from '../../utils/PageTitle'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../utils/PageContainer'
import PageNavigation from '../../utils/PageNavigation'
import TableLoading from '../../utils/TableLoading'
import ProgramsPageTable from './ProgramsPageTable'
import FormInput from '../../utils/FormInput'

const ProgramsPage = ({
    programs,
    hasMore,
    isFetching,
    onFetchMore,
    isFetchingMore,
    query,
    onChangeQuery,
}) => {
    const { i18n } = useLingui()
    return (
        <PageContainer>
            <PageNavigation />
            <PageContent>
                <PageTitle
                    rightChildren={(
                        <FormInput
                            className="w-auto"
                            placeholder={i18n._(/*i18n*/'Search')}
                            value={query}
                            onChange={onChangeQuery}
                        />
                    )}
                >
                    <Trans>Programs</Trans>
                </PageTitle>

                {isFetching ? (
                    <TableLoading />
                ) : (
                    <ProgramsPageTable
                        programs={programs}
                        hasMore={hasMore}
                        onFetchMore={onFetchMore}
                        isFetchingMore={isFetchingMore}
                    />
                )}
            </PageContent>
        </PageContainer>
    )
}

export default ProgramsPage
