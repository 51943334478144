import React from 'react'
import clsx from 'clsx'
import Checkbox from './Checkbox'

const FormCheckbox = ({
    label,
    description,
    htmlFor,
    className,
    labelClassName,
    descriptionClassName,
    ...props
}) => {
    const { name } = props

    return (
        <div
            className={(clsx(
                'relative flex items-start',
                className,
            ))}
        >
            <div className="flex items-center h-5">
                <Checkbox
                    id={name}
                    {...props}
                />
            </div>
            <div className="ml-1 text-sm leading-5">
                <label
                    htmlFor={name}
                    className={(clsx(
                        'ml-2 block text-sm text-gray-700 cursor-pointer',
                        labelClassName,
                    ))}
                >
                    {label}
                </label>
                {description && (
                    <p
                        className={(clsx(
                            'text-gray-500',
                            descriptionClassName
                        ))}
                    >
                        {description}
                    </p>
                )}
            </div>
        </div>
    )
}
export default FormCheckbox
