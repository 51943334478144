import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageTitle from '../../utils/PageTitle'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../utils/PageContainer'
import PageNavigation from '../../utils/PageNavigation'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableHeaderCell from '../../utils/TableHeaderCell'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import ForksPageTableRow from './ForksPageTableRow'

const ForksPage = ({
    hasMore,
    onFetchMore,
    isFetchingMore,
    cheapestPriceForks,
    onCreateFork,
    onDeleteForkCompleted,
    onUpdateForkCompleted,
}) => (
    <PageContainer>
        <PageNavigation />
        <PageContent>
            <div className="flex justify-between items-center">
                <PageTitle>
                    <Trans>Forks</Trans>
                </PageTitle>
                <div>
                    <Button onClick={onCreateFork}>
                        Create fork
                    </Button>
                </div>
            </div>
            <InfiniteScroll
                hasMore={hasMore}
                onFetchMore={onFetchMore}
                isFetchingMore={isFetchingMore}
            >
                <Table>
                    <thead>
                        <tr>
                            <TableHeaderCell>
                                <Trans>Code</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Min</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Max</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Blocked Airlines</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell />
                        </tr>
                    </thead>
                    <TableBody>
                        {cheapestPriceForks.map((cheapestPriceFork) => (
                            <ForksPageTableRow
                                key={cheapestPriceFork.id}
                                cheapestPriceFork={cheapestPriceFork}
                                onDeleteCompleted={onDeleteForkCompleted}
                                onUpdateCompleted={onUpdateForkCompleted}
                            />
                        ))}
                    </TableBody>
                </Table>
            </InfiniteScroll>
        </PageContent>
    </PageContainer>
)

export default ForksPage
