import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import TableLoading from '../../utils/TableLoading'
import PageContainer from '../../utils/PageContainer'
import PageNavigation from '../../utils/PageNavigation'
import EmptyTableCard from '../../utils/EmptyTableCard'
import InfiniteScroll from '../../utils/InfiniteScroll'
import BookingsPageTable from './BookingsPageTable'
import BookingsPageHeader from './BookingsPageHeader'

const BookingsPage = ({
    hasMore,
    bookings,
    isFetching,
    onFetchMore,
    isFetchingMore,
}) => (
    <PageContainer>
        <PageNavigation />
        <PageContent>
            <BookingsPageHeader />
            {(() => {
                if (isFetching) {
                    return <TableLoading />
                }
                if (bookings.length <= 0) {
                    return (
                        <EmptyTableCard
                            iconName="file"
                            title={<Trans>All bookings have a pax file </Trans>}
                            description={<Trans>No bookings without a pax file where found.</Trans>}
                        />
                    )
                }
                return (
                    <InfiniteScroll
                        hasMore={hasMore}
                        onFetchMore={onFetchMore}
                        isFetchingMore={isFetchingMore}
                    >
                        <BookingsPageTable bookings={bookings} />
                    </InfiniteScroll>
                )
            })()}
        </PageContent>
    </PageContainer>
)

export default BookingsPage
