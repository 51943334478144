import React from 'react'
import { Trans } from '@lingui/macro'
import { isRequired, isNumber } from '@connections/utils'
import useFormState, { createFormValidation } from 'use-form-state'

export const validation = createFormValidation([{
    path: 'code',
    validate: isRequired,
    message: <Trans>The iata code is required.</Trans>,
}, {
    path: 'min',
    validate: (value) => {
        if (value === '' || value === null) {
            return true
        }
        return isNumber(value)
    },
    message: <Trans>The min price must be a number.</Trans>,
}, {
    path: 'max',
    validate: (value) => {
        if (value === '' || value === null) {
            return true
        }
        return isNumber(value)
    },
    message: <Trans>The min price must be a number.</Trans>,
}])

const useCreateForkFormState = () => (
    useFormState({
        code: '',
        min: '',
        max: '',
        blockedAirlines: '',
    }, {
        validation,
    })
)

export default useCreateForkFormState
