import { useQuery, gql } from '@apollo/client'

export const ACCEPT_INVITATION_PAGE_QUERY = gql`
    query acceptInvitationPageQuery($id: String!) {
        invitation(id: $id) {
            id
            email
            expiresAt
            status
            user {
                id
                email
                firstName
                lastName
            }
            invitedBy {
                id
                firstName
                lastName
            }
        }
    }
`

const useAcceptInvitationPageQuery = (id) => {
    const variables = { id }
    const {
        data = {},
        error,
        loading,
        refetch,
    } = useQuery(ACCEPT_INVITATION_PAGE_QUERY, { variables })
    return {
        error,
        isFetching: loading,
        refetch,
        invitation: data.invitation || {},
    }
}

export default useAcceptInvitationPageQuery
