import React, { useEffect, useState } from 'react'
import useNotifications from '../../hooks/useNotifications'
import GiftcardsPage from './GiftcardsPage'
import useGiftcardsPageQuery from './useGiftcardsPageQuery'
import useCreateGiftcardPdfMutation from './useCreateGiftcardPdfMutation'

const GiftcardsPageWithState = () => {
    const [createModelIsOpen, setCreateModalIsOpen] = useState(false)
    const [imageList, setImageList] = useState([])
    const {
        hasMore,
        giftcards,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        refetch,
    } = useGiftcardsPageQuery()
    const { dispatchError } = useNotifications()
    const [createGiftcardPdf, { loading: isCreatingGiftcardPdf }] = useCreateGiftcardPdfMutation()

    const handleCreateGiftcard = () => {
        setCreateModalIsOpen(true)
    }

    const handleCloseCreateModal = () => {
        setCreateModalIsOpen(false)
    }

    const getImages = async () => {
        // This gets a list of images with the tag Giftcard
        const response = await fetch('https://media.connections.be/image/list/Giftcard.json')
        const { resources } = await response.json()
        setImageList(resources)
    }

    const handleDownloadPdf = async (giftcardBookingItem, locale) => {
        try {
            const input = { locale, bookingItemId: giftcardBookingItem.id, bookingId: giftcardBookingItem.booking.id }
            const base64Pdf = await createGiftcardPdf({ variables: { input } })
            const a = document.createElement('a')
            a.href = `data:application/pdf;base64,${base64Pdf.data.createGiftcardPdf}`
            a.download = `giftcard-${giftcardBookingItem.id}.pdf`
            a.click()
        } catch (error) {
            dispatchError(error)
        }
    }

    useEffect(() => {
        getImages()
    }, [])

    return (
        <GiftcardsPage
            hasMore={hasMore}
            giftcards={giftcards}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            isCreatingGiftcardPdf={isCreatingGiftcardPdf}
            onFetchMore={handleFetchMore}
            onDownloadPdf={handleDownloadPdf}
            onCreateGiftcard={handleCreateGiftcard}
            onCloseCreateModal={handleCloseCreateModal}
            createModelIsOpen={createModelIsOpen}
            handleRefetch={refetch}
            imageList={imageList}
        />
    )
}

export default GiftcardsPageWithState
