import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import useNotifications from '../hooks/useNotifications'
import Notification from './Notification'

const ToastMessages = () => {
    const { notifications, removeNotification } = useNotifications()
    return (
        <div className="fixed inset-0 flex flex-col items-end px-4 py-6 z-10 pointer-events-none sm:p-6">
            <AnimatePresence>
                {notifications.map((notification) => (
                    <AnimatedNotification
                        key={notification.createdAt}
                        type={notification.data.type}
                        title={notification.data.title}
                        message={notification.data.message}
                        onDismiss={() => removeNotification(notification)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        drag="x"
                        onDragEnd={() => removeNotification(notification)}
                    />
                ))}
            </AnimatePresence>
        </div>
    )
}

const AnimatedNotification = motion(Notification)

export default ToastMessages
