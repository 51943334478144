import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import TabsItem from './TabsItem'

const TabLink = ({
    to,
    exact,
    className,
    isDisabled,
    ...props
}) => {
    const isActive = useRouteMatch({
        path: to,
        exact
    })
    return (
        <Link
            to={to}
            onClick={(e) => isDisabled && e.preventDefault()}
        >
            <TabsItem
                className={className}
                isActive={isActive}
                isDisabled={isDisabled}
                {...props}
            />
        </Link>
    )
}

export default TabLink
