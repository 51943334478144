import { useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'

const QUERY = gql`
    query flightSearchResultsSync {
        flightSearchResultsSync {
            total
            completed
            failed
            finishedAt
            startedAt
        }
    }
`

const useFlightSearchResultSyncQuery = () => {
    const {
        data = {},
        error,
        loading,
        refetch,
        startPolling,
        stopPolling,
    } = useQuery(QUERY)
    const {
        finishedAt = null,
        startedAt = null,
        total = 0,
        completed = 0,
        failed = 0,
    } = data.flightSearchResultsSync || {}
    const isStarted = startedAt !== null
    const isFinished = finishedAt !== null
    const isSyncing = isStarted && !isFinished
    let progress = 0
    if (isStarted) {
        progress = ((completed + failed) / total) * 100
    }
    useEffect(() => {
        if (isSyncing) {
            startPolling(2000)
        }
        return () => {
            stopPolling()
        }
    }, [isStarted, isFinished, startPolling, stopPolling])
    return {
        error,
        refetch,
        isFetching: loading,
        isStarted,
        isSyncing,
        isFinished,
        progress,
    }
}

export default useFlightSearchResultSyncQuery
