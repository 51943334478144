import React from 'react'
import clsx from 'clsx'

const Tabs = ({
    className,
    children,
}) => (
    <div
        className={(clsx(
            'border-b border-gray-200',
            className
        ))}
    >
        <nav className="-mb-px flex space-x-8">
            {children}
        </nav>
    </div>
)

export default Tabs
