import React from 'react'

const LoginPageContainer = ({
    children,
    ...props
}) => (
    <div className="min-h-screen bg-white flex" {...props}>
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
                {children}
            </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
            <img
                className="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1436491865332-7a61a109cc05?auto=format&fit=crop&w=2253&q=80"
                alt="Intro"
            />
        </div>
    </div>
)

export default LoginPageContainer
