import React from 'react'
import Logo from '../../utils/Logo'

const LoginPageTitle = ({
    children,
    ...props
}) => (
    <div {...props}>
        <Logo className="h-14" />
        <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
            {children}
        </h2>
    </div>
)

export default LoginPageTitle
