import React from 'react'
import { I18nProvider } from '@lingui/react'
import { ApolloProvider } from '@apollo/client'
import NotificationsProvider from './providers/NotificationsProvider'

const AppProviders = ({
    apolloClient,
    notificationDispatcher,
    i18n,
    children,
}) => (
    <ApolloProvider client={apolloClient}>
        <I18nProvider i18n={i18n}>
            <NotificationsProvider dispatcher={notificationDispatcher}>
                {children}
            </NotificationsProvider>
        </I18nProvider>
    </ApolloProvider>
)

export default AppProviders
