import React from 'react'
import {
    isNumber,
    isRequired,
    isLessOrEqualThan,
    isGreaterOrEqualThan,
} from '@connections/utils'
import { Trans } from '@lingui/macro'
import { CURRENCY_ROUNDING } from '@connections/constants'
import useFormState, { createFormValidation } from 'use-form-state'

export const validation = createFormValidation([
    {
        path: 'code',
        validate: (value) => isRequired(value) && isNumber(value),
        message: <Trans>The code is required.</Trans>,
    },
    {
        path: 'paxFileId',
        validate: (value) => (
            isRequired(value)
            && isNumber(value)
            && isGreaterOrEqualThan(100000)(value)
            && isLessOrEqualThan(999999)(value)
        ),
        message: <Trans>The paxfile ID is required.</Trans>,
    },
    {
        path: 'amount',
        validate: (value) => isRequired(value) && isNumber(value),
        message: <Trans>The amount is required.</Trans>,
    },
    {
        path: 'from',
        validate: isRequired,
        message: <Trans>From is required.</Trans>,
    }, {
        path: 'to',
        validate: isRequired,
        message: <Trans>From is required.</Trans>,
    }, {
        path: 'message',
        validate: isRequired,
        message: <Trans>Message is required.</Trans>,
    },
])

const valuesToInput = ({
    code,
    paxFileId,
    amount,
    message,
    from,
    to,
    headerImage,
    footerImage,
}) => ({
    code,
    paxFileId,
    value: parseInt(amount, 10) * CURRENCY_ROUNDING,
    message,
    buyer: from,
    recipient: to,
    headerImage,
    footerImage,
})

const useGiftcardFormState = (initialValues = {}) => (
    useFormState({
        code: '',
        paxFileId: '',
        amount: '',
        from: '',
        to: '',
        message: '',
        headerImage: '',
        footerImage: '',
        ...initialValues
    }, {
        validation,
        valuesToInput,
    })
)

export default useGiftcardFormState
