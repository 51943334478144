import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'
import FormInputErrors from './FormInputErrors'

const FormInput = ({
    id,
    type = 'text',
    errors,
    hasError,
    iconNameBefore,
    iconNameAfter,
    className,
    inputClassName,
    disabled,
    value = '',
    onChange,
    prepend = '',
    as,
    ...props
}) => {
    const hasPrependValue = prepend.length > 0

    const inputProps = {
        id,
        type,
        disabled,
        className: clsx(
            'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-700 sm:text-sm',
            hasError && 'border-red-300 text-red-600 placeholder-red-300 focus:border-red-300 focus:ring-red',
            !disabled && 'shadow-sm',
            disabled && 'bg-gray-100',
            iconNameBefore && 'pl-9',
            (iconNameAfter || hasError) && 'pr-9',
            inputClassName,
            hasPrependValue && 'rounded-tl-none rounded-bl-none',
        ),
        value,
        onChange,
        ...props
    }

    let input = <input {...inputProps} />
    if (as === 'textarea') {
        input = (
            <textarea
                {...inputProps}
                className={clsx([
                    inputProps.className,
                    'resize-none min-h-36'
                ])}
            />
        )
    }

    return (
        <>
            <div className={clsx([
                'relative w-full',
                className,
            ])}
            >
                {hasPrependValue && (
                    <div className="bg-gray-50 flex items-center text-sm px-3 rounded-tl-md rounded-bl-md border border-gray-300 border-r-0 text-gray-500">
                        {prepend}
                    </div>
                )}
                <div className="relative rounded-md flex-1">
                    {(iconNameBefore) && (
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Icon
                                size="small"
                                name={iconNameAfter}
                            />
                        </div>
                    )}
                    {input}
                    {(iconNameAfter || hasError) && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <Icon
                                size="small"
                                name={hasError ? 'warning-circle' : iconNameAfter}
                                className={(clsx(
                                    hasError && 'text-red-500',
                                ))}
                            />
                        </div>
                    )}
                </div>
            </div>
            <FormInputErrors errors={errors} />
        </>
    )
}

export default FormInput
