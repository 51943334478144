import React from 'react'
import PageError from './utils/PageError'
import PageLoading from './utils/PageLoading'
import AppRouter from './AppRouter'

export default function App({
    error,
    history,
    isLoading,
    currentUser,
}) {
    if (isLoading) {
        return <PageLoading />
    }
    if (error) {
        return <PageError />
    }
    return (
        <AppRouter
            history={history}
            currentUser={currentUser}
        />
    )
}
