import { useMutation, gql } from '@apollo/client'

const MUTATION = gql`
    mutation updateCheapestPriceFork ($id: Int!, $input: CheapestPriceForkInput!) {
        updateCheapestPriceFork(id: $id, input: $input) {
            cheapestPriceFork {
                id
                code
                min
                max
                blockedAirlines
            }
        }
    }
`

const useUpdateForkMutation = (options) => useMutation(MUTATION, options)

export default useUpdateForkMutation
