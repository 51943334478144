import React from 'react'
import { gql } from '@apollo/client'
import TableCell from '../../utils/TableCell'
import ForksPageTableRowActionButtonWithState from './ForksPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment ForksPageTableRow on CheapestPriceFork {
        id
        code
        min
        max
        blockedAirlines
    }
`

const ForksPageTableRow = ({
    cheapestPriceFork,
    onDeleteCompleted,
    onUpdateCompleted,
}) => {
    const {
        code,
        min = null,
        max = null,
        blockedAirlines = null,
    } = cheapestPriceFork
    return (
        <tr>
            <TableCell>
                {code}
            </TableCell>
            <TableCell>
                {min && (
                    `€${min}`
                )}
            </TableCell>
            <TableCell>
                {max && (
                    `€${max}`
                )}
            </TableCell>
            <TableCell className="break-words text-ellipsis flex-1">
                {blockedAirlines}
            </TableCell>
            <TableCell className="text-right">
                <ForksPageTableRowActionButtonWithState
                    fork={cheapestPriceFork}
                    onDeleteCompleted={onDeleteCompleted}
                    onUpdateCompleted={onUpdateCompleted}
                />
            </TableCell>
        </tr>
    )
}

ForksPageTableRow.FRAGMENT = FRAGMENT

export default ForksPageTableRow
