import React from 'react'
import clsx from 'clsx'

const TableCell = ({
    className,
    hasText = true,
    children,
}) => (
    <td
        className={(clsx(
            'px-6 py-4',
            hasText && 'text-sm leading-5',
            className
        ))}
    >
        {children}
    </td>
)

export default TableCell
