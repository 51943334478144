import React from 'react'
import { Trans } from '@lingui/macro'
import { delay } from '@connections/utils'
import Button from '../../utils/Button'
import ProgressBar from '../../utils/ProgressBar'
import useFlightSearchResultSyncQuery from './useFlightSearchResultSyncQuery'

const FlightSearchResultsPageSyncButton = ({
    onClick,
}) => {
    const {
        progress,
        isSyncing,
        refetch,
    } = useFlightSearchResultSyncQuery()
    return (
        <div className="flex items-center">
            {isSyncing && (
                <div className="w-[150px] mr-4">
                    <div className="flex justify-between mb-1">
                        <span className="text-xs text-gray-600">
                            Syncing...
                        </span>
                        <span className="text-xs text-gray-600">
                            {parseInt(progress, 10)}%
                        </span>
                    </div>
                    <ProgressBar
                        className="bg-gray-300 rounded"
                        progress={progress}
                    />
                </div>
            )}
            <Button
                className="w-32"
                icon="sync"
                isLoading={isSyncing}
                iconBeforeProps={{
                    name: 'sync'
                }}
                disabled={isSyncing}
                onClick={async () => {
                    onClick()
                    await delay(3000)
                    refetch()
                }}
            >
                <Trans>Sync</Trans>
            </Button>
        </div>
    )
}

export default FlightSearchResultsPageSyncButton
