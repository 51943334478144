import { gql, useQuery } from '@apollo/client'
import PageInfo from '../../../apollo/PageInfoFragment'
import { connectionToCollection } from '../../../utils/graphql'
import useFetchMore from '../../hooks/useFetchMore'
import GiftcardsPageTableRow from './GiftcardsPageTableRow'

const QUERY = gql`
    query giftcardsPageQuery($after: String, $first: Int) {
        giftcardBookingItems(after: $after, first: $first) {
            edges {
                cursor
                node {
                    ...GiftcardsPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PageInfo}
    ${GiftcardsPageTableRow.FRAGMENT}
`

const useGiftcardsPageQuery = () => {
    const {
        error,
        refetch,
        loading,
        fetchMore,
        data = {},
    } = useQuery(QUERY, { fetchPolicy: 'network-only' })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(fetchMore, data, 'giftcardBookingItems')

    return {
        refetch,
        isFetchingMore,
        handleFetchMore,
        isFetching: loading,
        hasMore: !loading && !error && hasNextPage,
        giftcards: connectionToCollection(data?.giftcardBookingItems),
    }
}

export default useGiftcardsPageQuery
