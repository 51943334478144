import React from 'react'
import ActionButton from '../../utils/ActionButton'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'

const InvitationsPageTableRowActionButton = ({
    isLoading,
    onResendInvitation,
    onDeleteInvitation,
    canResendInvitation,
    canDeleteInvitation,
}) => (
    <DropdownMenu
        position="bottom-start"
        button={<ActionButton disabled={isLoading} />}
    >
        <DropdownMenuItem
            iconName="send"
            disabled={isLoading || !canResendInvitation}
            onClick={onResendInvitation}
        >
            Resend Invitation
        </DropdownMenuItem>
        <DropdownMenuItem
            iconName="bin"
            disabled={!canDeleteInvitation}
            onClick={onDeleteInvitation}
        >
            Delete invitation
        </DropdownMenuItem>
    </DropdownMenu>
)

export default InvitationsPageTableRowActionButton
