import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteInvitationModal from './DeleteInvitationModal'
import useDeleteInvitationMutation from './useDeleteInvitationMutation'

const DeleteInvitationModalWithState = ({
    invitation,
    isOpen,
    onClose,
    onCompleted,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteInvitation, { loading }] = useDeleteInvitationMutation({ onCompleted })
    return (
        <DeleteInvitationModal
            invitation={invitation}
            isOpen={isOpen}
            onClose={onClose}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { invitationId: invitation.id }
                    await deleteInvitation({ variables })
                    dispatchSuccess({
                        message: <Trans>{invitation.email} successfully deleted.</Trans>
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteInvitationModalWithState
