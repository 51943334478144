import React, { Fragment } from 'react'
import clsx from 'clsx'
import { Menu, Transition } from '@headlessui/react'
import Portal from './Portal'
import usePopper from '../hooks/usePopper'

const DropdownMenu = ({
    placement = 'bottom-end',
    strategy = 'fixed',
    buttonClassName,
    button,
    className,
    children,
}) => {
    const [trigger, container] = usePopper({
        placement,
        strategy,
        modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    })
    return (
        <Menu as="div" className="relative inline-block text-left z-10">
            {({ open }) => (
                <>
                    <Menu.Button ref={trigger} className={buttonClassName}>
                        {button}
                    </Menu.Button>
                    <Portal querySelector="#dropdowns">
                        <div ref={container}>
                            <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    as="ul"
                                    className={clsx(
                                        'w-60 py-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
                                        className,
                                    )}
                                >
                                    {children}
                                </Menu.Items>
                            </Transition>
                        </div>
                    </Portal>
                </>
            )}
        </Menu>
    )
}

export default DropdownMenu
