import React from 'react'
import clsx from 'clsx'

const ProgressBar = ({
    className,
    progress = 0,
    ...props
}) => (
    <div
        className={(clsx(
            'h-2 w-full overflow-hidden',
            className,
        ))}
        {...props}
    >
        <div style={{ width: `${progress}%` }} className="h-2 bg-primary" />
    </div>
)

export default ProgressBar
