import React, { useState } from 'react'
import UsersPageTableRowActionButton from './UsersPageTableRowActionButton'
import usePermissions from '../../hooks/usePermissions'
import DeleteUserModalWithState from './DeleteUserModalWithState'
import UpdateUserModalWithState from './UpdateUserModalWithState'

const UsersPageTableRowActionButtonWithState = ({
    user,
    onDeleteUserSuccess,
}) => {
    const {
        canDeleteUser,
        canChangeUserRole,
    } = usePermissions()
    const [isUpdatingUser, setIsUpdatingUser] = useState(false)
    const [isDeletingUser, setIsDeletingUser] = useState(false)

    return (
        <>
            <UsersPageTableRowActionButton
                user={user}
                canDeleteUser={canDeleteUser}
                canChangeUserRole={canChangeUserRole}
                onUpdateUser={() => setIsUpdatingUser(true)}
                onDeleteUser={() => setIsDeletingUser(true)}
            />
            {isUpdatingUser && (
                <UpdateUserModalWithState
                    isOpen
                    user={user}
                    onClose={() => setIsUpdatingUser(false)}
                />
            )}
            {isDeletingUser && (
                <DeleteUserModalWithState
                    isOpen
                    user={user}
                    onClose={() => setIsDeletingUser(false)}
                    onCompleted={onDeleteUserSuccess}
                />
            )}
        </>
    )
}

export default UsersPageTableRowActionButtonWithState
