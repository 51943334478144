import React from 'react'
import clsx from 'clsx'
import { AnimatePresence } from 'framer-motion'

const PageContainer = ({
    className,
    ...props
}) => (
    <AnimatePresence>
        <div
            data-testid="PageContainer"
            className={clsx(
                'h-screen overflow-hidden bg-gray-100',
                className,
            )}
            {...props}
        />
    </AnimatePresence>
)

export default PageContainer
