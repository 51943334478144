import { useMutation, gql } from '@apollo/client'
import CurrentUser from '../../../apollo/CurrentUserFragment'

export const REGISTER_MUTATION = gql`
    mutation registerMutation($invitationId: String!, $input: RegisterInput!) {
        register(invitationId: $invitationId, input: $input) {
            accessToken
            refreshToken
            currentUser {
                ...CurrentUser
            }
        }
    }
    ${CurrentUser}
`

const useRegisterMutation = (options) => useMutation(REGISTER_MUTATION, options)

export default useRegisterMutation
