import React from 'react'

const HydrateSafely = ({ children }) => {
    const [isMounted, setIsMounted] = React.useState(false)
    React.useEffect(() => {
        setIsMounted(true)
    }, [])

    if (!isMounted) {
        return null
    }

    return children
}

export default HydrateSafely
