import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import PageTitle from '../../utils/PageTitle'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../utils/PageContainer'
import PageNavigation from '../../utils/PageNavigation'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableHeaderCell from '../../utils/TableHeaderCell'
import FlightSearchResultsPageTableRow from './FlightSearchResultsPageTableRow'
import FlightSearchResultsPageSyncButton from './FlightSearchResultsPageSyncButton'
import FlightSearchResultsPageFilters from './FlightSearchResultsPageFilters'

const FlightSearchResultsPage = ({
    hasMore,
    onFetchMore,
    isFetchingMore,
    flightSearchResults,
    selectedAirlineCode,
    selectedAirportCode,
    flightSearchResultsAirports,
    flightSearchResultsAirlines,
    onSyncFlightSearchResults,
    onChangeSelectedAirlineCode,
    onChangeSelectedAirportCode,
    onClearFilters,
}) => (
    <PageContainer>
        <PageNavigation />
        <PageContent>
            <div className="flex justify-between items-center">
                <PageTitle>
                    <Trans>Flight search results</Trans>
                </PageTitle>
                <div>
                    <FlightSearchResultsPageSyncButton
                        onClick={onSyncFlightSearchResults}
                    />
                </div>
            </div>
            <FlightSearchResultsPageFilters
                selectedAirlineCode={selectedAirlineCode}
                selectedAirportCode={selectedAirportCode}
                flightSearchResultsAirports={flightSearchResultsAirports}
                flightSearchResultsAirlines={flightSearchResultsAirlines}
                onChangeSelectedAirlineCode={onChangeSelectedAirlineCode}
                onChangeSelectedAirportCode={onChangeSelectedAirportCode}
                onClearFilters={onClearFilters}
            />
            <InfiniteScroll
                hasMore={hasMore}
                onFetchMore={onFetchMore}
                isFetchingMore={isFetchingMore}
            >
                <Table>
                    <thead>
                        <tr>
                            <TableHeaderCell>
                                <Trans>Departure</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Arrival</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Departure date</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Arrival date</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Price</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Carrier</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Provider</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Direct flight</Trans>
                            </TableHeaderCell>
                        </tr>
                    </thead>
                    <TableBody>
                        {flightSearchResults.map((flightSearchResult) => (
                            <FlightSearchResultsPageTableRow
                                key={flightSearchResult.id}
                                flightSearchResult={flightSearchResult}
                            />
                        ))}
                    </TableBody>
                </Table>
            </InfiniteScroll>
        </PageContent>
    </PageContainer>
)

export default FlightSearchResultsPage
