import React from 'react'
import clsx from 'clsx'
import TableBody from './TableBody'
import TableCell from './TableCell'
import TableHeaderCell from './TableHeaderCell'

const Table = ({
    className,
    children,
    variant,
    ...props
}) => (
    <div
        className={clsx(
            'overflow-hidden shadow border-b border-gray-200 sm:rounded-lg',
            className
        )}
        {...props}
    >
        <table className="min-w-full divide-y divide-gray-200">
            {children}
        </table>
    </div>
)

Table.Body = TableBody
Table.Cell = TableCell
Table.HeaderCell = TableHeaderCell

export default Table
