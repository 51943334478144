import React from 'react'
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom'
import ProgramsPageWithState from './pages/programs/ProgramsPageWithState'
import GiftcardsPageWithState from './pages/giftcards/GiftcardsPageWithState'

export default function ShopRouter({
    pageProps
}) {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/programs" />
            </Route>
            <Route exact path="/programs">
                <ProgramsPageWithState {...pageProps} />
            </Route>
            <Route exact path="/giftcards">
                <GiftcardsPageWithState {...pageProps} />
            </Route>
            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    )
}
