import { useMutation, gql } from '@apollo/client'

export const UPDATE_USER_MUTATION = gql`
    mutation updateUserMutation ($userId: Int!, $input: UpdateUserInput!) {
        updateUser(userId: $userId, input: $input) {
            id
            email
            role {
                id
                type
            }
        }
    }
`

const useUpdateUserMutation = () => useMutation(UPDATE_USER_MUTATION)

export default useUpdateUserMutation
