import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useCreatePaxFileForBookingMutation from './useCreatePaxFileForBookingMutation'
import AddPaxFileIdToBookingModalWithState from './AddPaxFileIdToBookingModalWithState'
import BookingsNoPaxFilePageTableRowActionButton from './BookingsNoPaxFilePageTableRowActionButton'

const BookingsNoPaxFilePageTableRowActionButtonWithState = ({
    bookingUid,
}) => {
    const [isAddingPaxFileId, setIsAddingPaxFileId] = useState(false)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [createPaxFileForBooking, { loading }] = useCreatePaxFileForBookingMutation()

    const handleCreatePaxFileForBooking = async () => {
        try {
            const variables = { bookingUid }
            await createPaxFileForBooking({ variables })
            dispatchSuccess({
                message: <Trans>Pax file successfully created.</Trans>
            })
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }

    return (
        <>
            <BookingsNoPaxFilePageTableRowActionButton
                isLoading={loading}
                onAddPaxFileId={() => setIsAddingPaxFileId(true)}
                onCreatePaxFileForBooking={handleCreatePaxFileForBooking}
            />
            {isAddingPaxFileId && (
                <AddPaxFileIdToBookingModalWithState
                    isOpen
                    bookingUid={bookingUid}
                    onClose={() => setIsAddingPaxFileId(false)}
                />
            )}
        </>
    )
}

export default BookingsNoPaxFilePageTableRowActionButtonWithState
