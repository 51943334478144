export const isUnauthenticatedError = (error) => (
    error.extensions && error.extensions.code === 'UNAUTHORIZED_ERROR'
)

export const mergeQueryResults = (previousResult, fetchMoreResult, path) => {
    const {
        edges: newEdges,
        totalCount,
        pageInfo,
    } = fetchMoreResult[path]

    return newEdges.length ? {
        [path]: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult[path].__typename,
            edges: [...previousResult[path].edges, ...newEdges],
            pageInfo,
            totalCount,
        },
    } : previousResult
}

export const connectionToCollection = (connection = { edges: [] }) => (
    connection.edges.map((edge) => edge.node)
)
