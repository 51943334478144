import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import AcceptInvitationPageContainer from './AcceptInvitationPageContainer'

const RegisterPage = ({
    email,
    onSubmit,
    formState,
    invitation,
    isRegistering,
}) => {
    const {
        getErrorMessages,
        getNativeInputProps,
    } = formState
    return (
        <AcceptInvitationPageContainer invitation={invitation}>
            <div className="space-y-6">
                <FormGroup
                    label={<Trans>Email address</Trans>}
                    htmlFor="email"
                >
                    <FormInput
                        id="email"
                        className="max-w-lg"
                        disabled
                        value={email}
                    />
                </FormGroup>
                <FormGroup
                    label={<Trans>First name</Trans>}
                    htmlFor="firstName"
                >
                    <FormInput
                        id="firstName"
                        className="max-w-lg"
                        errors={getErrorMessages('firstName')}
                        {...getNativeInputProps('firstName')}
                    />
                </FormGroup>
                <FormGroup
                    label={<Trans>last name</Trans>}
                    htmlFor="lastName"
                >
                    <FormInput
                        id="password"
                        className="max-w-lg"
                        errors={getErrorMessages('lastName')}
                        {...getNativeInputProps('lastName')}
                    />
                </FormGroup>
                <FormGroup
                    label={<Trans>Password</Trans>}
                    htmlFor="password"
                >
                    <FormInput
                        id="password"
                        type="password"
                        className="max-w-lg"
                        errors={getErrorMessages('password')}
                        {...getNativeInputProps('password')}
                    />
                </FormGroup>
                <div className="mt-6">
                    <Button
                        type="submit"
                        data-testid="RegisterButton"
                        onClick={onSubmit}
                        isLoading={isRegistering}
                        disabled={isRegistering}
                        className="w-full"
                    >
                        <Trans>Register</Trans>
                    </Button>
                </div>
            </div>
        </AcceptInvitationPageContainer>
    )
}

export default RegisterPage
