import React from 'react'
import Table from '../../utils/Table'
import InfiniteScroll from '../../utils/InfiniteScroll'
import ProgramsPageTableRow from './ProgramsPageTableRow'
import ProgramsPageTableHeaderRow from './ProgramsPageTableHeaderRow'

const ProgramsPageTable = ({
    programs,
    hasMore,
    onFetchMore,
    isFetchingMore,
}) => (
    <InfiniteScroll
        hasMore={hasMore}
        onFetchMore={onFetchMore}
        isFetchingMore={isFetchingMore}
    >
        <Table data-testid="InvitationsPageTable">
            <thead>
                <ProgramsPageTableHeaderRow />
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {programs.map((tour) => (
                    <ProgramsPageTableRow
                        tour={tour}
                        key={tour.uuid}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default ProgramsPageTable
