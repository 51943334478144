import React from 'react'
import clsx from 'clsx'

const TableHeaderCell = ({
    align = 'left',
    children
}) => (
    <th className={
        clsx(
            'px-6 py-3 bg-gray-50 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider',
            align === 'left' && 'text-left',
            align === 'right' && 'text-right',
            align === 'center' && 'text-center',
        )
    }
    >
        {children}
    </th>
)

export default TableHeaderCell
