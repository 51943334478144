import { useMutation, gql } from '@apollo/client'
import CurrentUser from '../../../apollo/CurrentUserFragment'

export const LOGIN_MUTATION = gql`
    mutation loginWithPassword($input: LoginWithPasswordInput!) {
        loginWithPassword(input: $input) {
            accessToken
            refreshToken
            currentUser {
                ...CurrentUser
            }
        }
    }
    ${CurrentUser}
`

const useLoginMutation = (options) => useMutation(LOGIN_MUTATION, options)

export default useLoginMutation
