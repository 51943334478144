import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'

const ProgramsPageTableHeaderRow = () => (
    <tr>
        <TableHeaderCell>
            <Trans>Date created</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Booker</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Departuredate</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Travelers</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>PNR</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Pax file</Trans>
        </TableHeaderCell>
        <TableHeaderCell />
    </tr>
)

export default ProgramsPageTableHeaderRow
