import React from 'react'
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom'
import UsersPageWithState from './pages/users/UsersPageWithState'
import ForksPageWithState from './pages/forks/ForksPageWithState'
import ProgramsPageWithState from './pages/programs/ProgramsPageWithState'
import BookingsPageWithState from './pages/bookings/BookingsPageWithState'
import InvitationsPageWithState from './pages/users-invitations/InvitationsPageWithState'
import AcceptInvitationPageWithState from './pages/invitation-accept/AcceptInvitationPageWithState'
import BookingsNoPaxFilePageWithState from './pages/bookings-no-pax-file/BookingsNoPaxFilePageWithState'
import FlightSearchResultsPageWithState from './pages/flight-search-results/FlightSearchResultsPageWithState'
import GiftcardsPageWithState from './pages/giftcards/GiftcardsPageWithState'

export default function AdminRouter({
    pageProps
}) {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/bookings" />
            </Route>
            <Route exact path="/forks">
                <ForksPageWithState {...pageProps} />
            </Route>
            <Route exact path="/users">
                <UsersPageWithState {...pageProps} />
            </Route>
            <Route exact path="/users/invitations">
                <InvitationsPageWithState {...pageProps} />
            </Route>
            <Route exact path="/flight-search-results">
                <FlightSearchResultsPageWithState {...pageProps} />
            </Route>
            <Route exact path="/invitation/:id/accept">
                <AcceptInvitationPageWithState {...pageProps} />
            </Route>
            <Route exact path="/programs">
                <ProgramsPageWithState {...pageProps} />
            </Route>
            <Route exact path="/bookings">
                <BookingsPageWithState {...pageProps} />
            </Route>
            <Route exact path="/bookings/no-pax-file">
                <BookingsNoPaxFilePageWithState {...pageProps} />
            </Route>
            <Route exact path="/giftcards">
                <GiftcardsPageWithState {...pageProps} />
            </Route>
            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    )
}
