import React from 'react'
import clsx from 'clsx'

const TabsItem = ({
    className,
    isActive,
    isDisabled,
    children,
}) => (
    <button
        type="button"
        aria-current={isActive && 'page'}
        className={(clsx(
            'whitespace-no-wrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 focus:outline-none',
            !isActive && '',
            isActive && 'whitespace-no-wrap pb-4 px-1 border-b-2 border-primary-500 font-medium text-sm leading-5 focus:outline-none',
            !isActive && !isDisabled && 'text-gray-500 hover:text-gray-700 hover:border-gray-300',
            isDisabled && 'text-gray-300 cursor-not-allowed',
            className
        ))}
    >
        {children}
    </button>
)

export default TabsItem
