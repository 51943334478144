import { useMutation, gql } from '@apollo/client'

const MUTATION = gql`
    mutation createGiftcard ($input: CreateGiftcardInput!) {
        createGiftcard(input: $input) {
            giftcard {
                id
            }
        }
    }
`

const useCreateGiftcardMutation = (options) => useMutation(MUTATION, options)

export default useCreateGiftcardMutation
