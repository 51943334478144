import React from 'react'
import { Trans } from '@lingui/macro'
import ActionButton from '../../utils/ActionButton'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'

const UsersPageTableRowActionButton = ({
    isLoading,
    onUpdateUser,
    onDeleteUser,
    canDeleteUser,
    canChangeUserRole,
}) => (
    <DropdownMenu
        position="bottom-start"
        button={<ActionButton disabled={isLoading} />}
    >
        <DropdownMenuItem
            iconName="edit"
            onClick={onUpdateUser}
            disabled={isLoading || !canChangeUserRole}
        >
            <Trans>Edit user role</Trans>
        </DropdownMenuItem>
        <DropdownMenuItem
            iconName="bin"
            onClick={onDeleteUser}
            disabled={!canDeleteUser}
        >
            <Trans>Delete user</Trans>
        </DropdownMenuItem>
    </DropdownMenu>
)

export default UsersPageTableRowActionButton
