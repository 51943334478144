import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import UpdateUserModal from './UpdateUserModal'
import useUpdateUserMutation from './useUpdateUserMutation'
import useUpdateUserFormState from './useUpdateUserFormState'
import useCreateInvitationModalQuery from './useCreateInvitationModalQuery'

const UpdateUserModalWithState = ({
    user,
    isOpen,
    onClose,
}) => {
    const { roles } = useCreateInvitationModalQuery()
    const formState = useUpdateUserFormState({ email: user.email, roleId: user.role.id })
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [updateUser, { loading }] = useUpdateUserMutation()
    return (
        <UpdateUserModal
            roles={roles}
            isOpen={isOpen}
            onClose={onClose}
            formState={formState}
            isUpdatingUser={loading}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const variables = {
                            userId: user.id,
                            input: formState.valuesToInput(),
                        }
                        await updateUser({ variables })
                        dispatchSuccess({
                            message: <Trans>{user.email} successfully updated.</Trans>
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    } finally {
                        onClose()
                    }
                }
            }}
        />
    )
}

export default UpdateUserModalWithState
