import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const DELETE_USER_MUTATION = gql`
    mutation deleteUser($userId: Int!) {
        deleteUser(userId: $userId)
    }
`

const useDeleteUserMutation = (options) => (
    useMutation(DELETE_USER_MUTATION, options)
)

export default useDeleteUserMutation
