import React from 'react'
import { formatSBPrice } from '../../../utils/formatting'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'

const Button = ({
    slug,
    lang,
}) => (
    <a
        href={`${process.env.NEXT_PUBLIC_APP_WEB_URL}/api/quotes/${lang}/${slug}`}
        target="_blank"
        rel="noreferrer"
        className="hover:bg-primary-700 py-1 px-2"
    >
        {lang}
    </a>
)

const ProgramsPageTableRow = ({
    tour,
}) => {
    const {
        full_slug,
        content: {
            name,
            price,
            description
        },
        translated_slugs,
    } = tour

    return (
        <tr data-testid="ProgramsPageTableRow">
            <TableCell hasText>
                <div className="flex gap-2 items-center">
                    {name}
                    {full_slug.includes('_test/') && (
                        <Tag className="bg-orange-300">test</Tag>
                    )}
                    {full_slug.includes('preview/') && (
                        <Tag className="bg-orange-300">preview</Tag>
                    )}
                </div>
            </TableCell>
            <TableCell hasText>
                <div className="max-w-xs line-clamp-2">
                    {description}
                </div>
            </TableCell>
            <TableCell hasText>
                {formatSBPrice(price)}
            </TableCell>
            <TableCell hasText>
                <div className="flex justify-end">
                    <div className="flex uppercase bg-primary rounded-md text-white overflow-hidden">
                        <Button
                            lang="nl"
                            slug={full_slug}
                        />
                        {translated_slugs.map(({ lang, path }) => (
                            <Button
                                lang={lang}
                                slug={path}
                            />
                        ))}
                    </div>
                </div>
            </TableCell>
        </tr>
    )
}

export default ProgramsPageTableRow
