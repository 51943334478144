import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import FormSelect from '../../utils/FormSelect'

const FlightSearchResultsPageFilters = ({
    selectedAirlineCode,
    selectedAirportCode,
    flightSearchResultsAirports,
    flightSearchResultsAirlines,
    onChangeSelectedAirlineCode,
    onChangeSelectedAirportCode,
    onClearFilters,
}) => (
    <div className="flex items-center mb-4">
        <FormSelect
            onChange={(e) => onChangeSelectedAirlineCode(e.target.value)}
            value={selectedAirlineCode}
            className="min-w-[96px] mr-4"
        >
            <option value="">Airline</option>
            {flightSearchResultsAirlines.map(({ code, name }) => (
                <option value={code}>{name} ({code})</option>
            ))}
        </FormSelect>
        <FormSelect
            value={selectedAirportCode}
            onChange={(e) => onChangeSelectedAirportCode(e.target.value)}
            className="min-w-[96px] mr-4"
        >
            <option value="">Airport</option>
            {flightSearchResultsAirports.map(({ code, name }) => (
                <option value={code}>{name} ({code})</option>
            ))}
        </FormSelect>
        {(selectedAirportCode || selectedAirlineCode) && (
            <div className="mt-1">
                <Button
                    variant="white"
                    iconBeforeProps={{ name: 'cross', className: 'text-primary' }}
                    onClick={onClearFilters}
                >
                    <Trans>Clear</Trans>
                </Button>
            </div>
        )}
    </div>
)

export default FlightSearchResultsPageFilters
