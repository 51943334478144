import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import LoginPageTitle from '../login/LoginPageTitle'
import LoginPageContainer from '../login/LoginPageContainer'

const InvitationInvalidPage = ({
    onDismiss,
}) => (
    <LoginPageContainer>
        <LoginPageTitle className="mb-16">
            <Trans>This invitation is no longer valid</Trans>
        </LoginPageTitle>
        <div className="mt-16">
            <Button
                data-testid="AcceptInvitationButton"
                onClick={onDismiss}
                className="w-full"
            >
                <Trans>Close</Trans>
            </Button>
        </div>
    </LoginPageContainer>
)

export default InvitationInvalidPage
