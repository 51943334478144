import React from 'react'
import { useDebounce } from '@wappla/react-hooks'
import ProgramsPage from './ProgramsPage'
import useProgramsPageQuery from './useProgramsPageQuery'

const ProgramsPageWithState = () => {
    const [query, setQuery] = React.useState('')
    const debouncedQuery = useDebounce(query, 500)
    const {
        hasMore,
        programs,
        isFetching,
        isFetchingMore,
        handleFetchMore,
    } = useProgramsPageQuery(debouncedQuery)

    return (
        <>
            <ProgramsPage
                programs={programs}
                hasMore={hasMore}
                isFetching={isFetching}
                onFetchMore={handleFetchMore}
                isFetchingMore={isFetchingMore}
                query={query}
                onChangeQuery={(e) => setQuery(e.target.value)}
            />
        </>
    )
}

export default ProgramsPageWithState
