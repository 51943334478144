import { useMutation, gql } from '@apollo/client'

const MUTATION = gql`
    mutation updateGiftcard($bookingId: ID!, $bookingItemId: ID!, $input: UpdateGiftcardInput!) {
        updateGiftcard(bookingId: $bookingId, bookingItemId: $bookingItemId, input: $input) {
            giftcard {
                id
            }
        }
    }
`

const useUpdateGiftcardMutation = (options) => useMutation(MUTATION, options)

export default useUpdateGiftcardMutation
