import React from 'react'
import UsersPage from './UsersPage'
import useUsersPageQuery from './useUsersPageQuery'

const UsersPageWithState = () => {
    const {
        users,
        refetch,
        hasMore,
        isFetching,
        isFetchingMore,
        handleFetchMore,
    } = useUsersPageQuery()
    return (
        <UsersPage
            users={users}
            hasMore={hasMore}
            isFetching={isFetching}
            onDeleteUserSuccess={refetch}
            onFetchMore={handleFetchMore}
            isFetchingMore={isFetchingMore}
        />
    )
}

export default UsersPageWithState
