import { useMutation, gql } from '@apollo/client'

export const CREATE_PAX_FILE_FOR_BOOKING_MUTATION = gql`
    mutation createPaxFileForBookingMutation ($bookingUid: ID!) {
        createPaxFileForBooking(bookingUid: $bookingUid) {
            id
            data
        }
    }
`

const useCreatePaxFileForBookingMutation = () => useMutation(CREATE_PAX_FILE_FOR_BOOKING_MUTATION, { refetchQueries: ['bookingsPageQuery'] })

export default useCreatePaxFileForBookingMutation
