import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'

const ProgramsPageTableHeaderRow = () => (
    <tr>
        <TableHeaderCell>
            <Trans>Name</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Description</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Price</Trans>
        </TableHeaderCell>
        <TableHeaderCell align="right">
            <Trans>Download</Trans>
        </TableHeaderCell>
    </tr>
)

export default ProgramsPageTableHeaderRow
