import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import TableLoading from '../../utils/TableLoading'
import PageContainer from '../../utils/PageContainer'
import PageNavigation from '../../utils/PageNavigation'
import EmptyTableCard from '../../utils/EmptyTableCard'
import InfiniteScroll from '../../utils/InfiniteScroll'
import GiftcardsPageTable from './GiftcardsPageTable'
import PageTitle from '../../utils/PageTitle'
import Button from '../../utils/Button'
import CreateGiftcardModalWithState from './CreateGiftcardModalWithState'

const GiftcardsPage = ({
    hasMore,
    giftcards,
    isFetching,
    onFetchMore,
    onDownloadPdf,
    isFetchingMore,
    isCreatingGiftcardPdf,
    onCreateGiftcard,
    createModelIsOpen,
    onCloseCreateModal,
    handleRefetch,
    imageList,
}) => (
    <PageContainer>
        <PageNavigation />
        <PageContent>
            <div className="flex justify-between items-center">
                <PageTitle>
                    <Trans>Giftcards</Trans>
                </PageTitle>
                <Button onClick={onCreateGiftcard}>
                    <Trans>Create giftcard</Trans>
                </Button>
            </div>
            {(() => {
                if (isFetching) {
                    return <TableLoading />
                }
                if (giftcards.length <= 0) {
                    return (
                        <EmptyTableCard
                            iconName="file"
                            title={<Trans>No giftcards found.</Trans>}
                        />
                    )
                }
                return (
                    <InfiniteScroll
                        hasMore={hasMore}
                        onFetchMore={onFetchMore}
                        isFetchingMore={isFetchingMore}
                    >
                        <GiftcardsPageTable
                            giftcards={giftcards}
                            onDownloadPdf={onDownloadPdf}
                            onUpdateCompleted={handleRefetch}
                            imageList={imageList}
                            isCreatingGiftcardPdf={isCreatingGiftcardPdf}
                        />
                    </InfiniteScroll>
                )
            })()}
        </PageContent>
        {createModelIsOpen ? (
            <CreateGiftcardModalWithState
                isOpen={createModelIsOpen}
                onClose={onCloseCreateModal}
                onCompleted={handleRefetch}
                imageList={imageList}
            />
        ) : null}
    </PageContainer>
)

export default GiftcardsPage
