import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const PRIMARY = 'primary'
const DANGER = 'danger'
const WHITE = 'white'

const IconBefore = ({
    className,
    size = 'small',
    ...props
}) => (
    <Icon
        size={size}
        className={clsx(
            'mr-2',
            className,
        )}
        {...props}
    />
)

const IconAfter = ({
    className,
    size = 'small',
    ...props
}) => (
    <Icon
        size={size}
        className={clsx(
            'ml-2',
            className,
        )}
        {...props}
    />
)

const Button = ({
    className,
    children,
    isLoading,
    disabled,
    variant = PRIMARY,
    iconBeforeProps,
    iconAfterProps,
    ...props
}) => (
    <button
        type="button"
        disabled={disabled}
        className={(clsx(
            'inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md transition duration-150 ease-in-out shadow-sm',
            variant === PRIMARY && 'text-white bg-primary hover:bg-primary-700',
            variant === DANGER && 'text-white bg-red-600 hover:bg-red-500',
            variant === WHITE && 'border border-gray-300 text-gray-700 bg-white hover:text-gray-800 hover:bg-gray-50',
            disabled && 'cursor-not-allowed opacity-75',
            className,
        ))}
        {...props}
    >
        {isLoading && (
            <Icon
                name="spinner"
                className="animate-spin mr-1"
                color="white"
                size="small"
            />
        )}
        {!isLoading && iconBeforeProps && (
            <IconBefore
                {...iconBeforeProps}
            />
        )}
        {children}
        {iconAfterProps && (
            <IconAfter
                {...iconAfterProps}
            />
        )}
    </button>
)

export default Button
