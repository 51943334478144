import React from 'react'
import { Trans } from '@lingui/macro'
import Modal, { ModalTitle, ModalBody } from '../../utils/Modal'
import Button from '../../utils/Button'
import GiftcardModalForm from './GiftcardModalForm'

const EditGiftcardModal = ({
    isOpen,
    onClose,
    isLoading,
    formState,
    onSubmit,
    imageList,
}) => (
    <Modal
        isOpen={isOpen}
        onClose={onClose}
    >
        <ModalTitle
            title={<Trans>Update giftcard</Trans>}
            onClose={onClose}
        />
        <ModalBody>
            <GiftcardModalForm
                formState={formState}
                imageList={imageList}
            />
        </ModalBody>
        <div className="flex justify-end">
            <Button
                className="mr-4"
                variant="white"
                onClick={onClose}
                disabled={isLoading}
            >
                <Trans>Cancel</Trans>
            </Button>
            <Button
                onClick={onSubmit}
                isLoading={isLoading}
                disabled={isLoading}
            >
                <Trans>Update</Trans>
            </Button>
        </div>
    </Modal>
)

export default EditGiftcardModal
