import React from 'react'
import { Trans } from '@lingui/macro'
import { isRequired } from '@connections/utils'
import useFormState, { createFormValidation } from 'use-form-state'

const validation = createFormValidation([{
    path: 'firstName',
    validate: isRequired,
    message: <Trans>Your first name is required</Trans>,
}, {
    path: 'lastName',
    validate: isRequired,
    message: <Trans>Your last name is required</Trans>,
}, {
    path: 'password',
    validate: (password) => password !== '',
    message: <Trans>The password field is required</Trans>,
}])

const useRegisterFormState = () => (
    useFormState({
        firstName: '',
        lastName: '',
        password: '',
    }, {
        validation,
    })
)

export default useRegisterFormState
