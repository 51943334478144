import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const Spinner = ({
    className,
    ...props
}) => (
    <Icon
        name="spinner"
        className={(clsx(
            'animate-spin',
            className,
        ))}
        {...props}
    />
)

export default Spinner
