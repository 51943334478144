import React from 'react'
import clsx from 'clsx'
import { variant } from '../../utils/clsx'

const Check = () => (
    <path d="M18.71 7.21a1 1 0 00-1.42 0l-7.45 7.46-3.13-3.14A1.022 1.022 0 105.29 13l3.84 3.84a1 1 0 001.42 0l8.16-8.16a1 1 0 000-1.47z" />
)

const CheckCircle = () => (
    <>
        <path d="M12 21C9.61305 21 7.32387 20.0518 5.63604 18.364C3.94821 16.6761 3 14.3869 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21V21Z" />
        <path d="M16 10L11 15L8 12" />
    </>
)

const Spinner = () => (
    <path d="M12 6V3M16.5 4.206l-1.502 2.602M17.192 9.002L19.794 7.5M21 12h-3M6 12H3M4.206 7.5l2.602 1.502M7.5 4.206l1.502 2.602M12 21v-3M9.002 17.192L7.5 19.794M6.808 14.998L4.206 16.5M17.192 14.998l2.602 1.502M14.998 17.192l1.502 2.602" />
)

const ChevronDown = () => (
    <path d="M17 9.17a1 1 0 00-1.41 0L12 12.71 8.46 9.17a1 1 0 10-1.41 1.42l4.24 4.24a1 1 0 001.42 0L17 10.59a1 1 0 000-1.42z" />
)

const ChevronLeft = () => (
    <path d="M11.29 12l3.54-3.54a1 1 0 10-1.42-1.41l-4.24 4.24a1 1 0 000 1.42L13.41 17a1 1 0 00.71.29 1 1 0 00.71-.29 1 1 0 000-1.41L11.29 12z" />
)

const ChevronRight = () => (
    <path d="M14.83 11.29l-4.24-4.24a1 1 0 10-1.42 1.41L12.71 12l-3.54 3.54a1 1 0 000 1.41 1 1 0 00.71.29 1 1 0 00.71-.29l4.24-4.24a1 1 0 000-1.42z" />
)

const File = () => (
    <>
        <path d="m18.414 6.414-2.828-2.828A2 2 0 0 0 14.172 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414v0Z" />
        <path d="M19 8h-4a1 1 0 0 1-1-1V3M8 11h6M8 14h6M8 17h4.333" />
    </>
)

const Search = () => (
    <>
        <path d="M11.059 18.118C14.9576 18.118 18.118 14.9576 18.118 11.059C18.118 7.16042 14.9576 4 11.059 4C7.16042 4 4 7.16042 4 11.059C4 14.9576 7.16042 18.118 11.059 18.118Z" />
        <path d="M20 20L16.05 16.05" />
    </>
)

const Sync = () => (
    <>
        <path d="M17.25 8.25H21V4.5" />
        <path d="M3 12C3 9.61305 3.94822 7.32387 5.63604 5.63604C7.32387 3.94821 9.61306 3 12 3C15.63 3 18.751 5.154 20.174 8.25" />
        <path d="M6.75 15.75H3V19.5" />
        <path d="M21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21C8.37 21 5.249 18.846 3.826 15.75" />
    </>
)

const User = () => (
    <>
        <path d="M5 20C5 17.5 7 15.6 9.4 15.6H14.5C17 15.6 18.9 17.6 18.9 20" />
        <path d="M15 5.2C16.7 6.9 16.7 9.6 15 11.2C13.3 12.8 10.6 12.9 9.00001 11.2C7.40001 9.5 7.30001 6.8 9.00001 5.2C10.7 3.6 13.3 3.6 15 5.2" />
    </>
)

const Users = () => (
    <>
        <path d="M3 18C3 16.9391 3.42143 15.9217 4.17157 15.1716C4.92172 14.4214 5.93913 14 7 14H11C12.0609 14 13.0783 14.4214 13.8284 15.1716C14.5786 15.9217 15 16.9391 15 18" />
        <path d="M16 4.651C16.663 4.651 17.2989 4.91439 17.7678 5.38323C18.2366 5.85207 18.5 6.48796 18.5 7.151C18.5 7.81404 18.2366 8.44993 17.7678 8.91877C17.2989 9.38761 16.663 9.651 16 9.651" />
        <path d="M11.405 4.996C12.0428 5.63385 12.4012 6.49895 12.4012 7.401C12.4012 8.30305 12.0428 9.16815 11.405 9.806C10.7672 10.4438 9.90205 10.8022 9 10.8022C8.09795 10.8022 7.23284 10.4438 6.595 9.806C5.95715 9.16815 5.59882 8.30305 5.59882 7.401C5.59882 6.49895 5.95715 5.63385 6.595 4.996C7.23285 4.35815 8.09795 3.99982 9 3.99982C9.90205 3.99982 10.7672 4.35815 11.405 4.996" />
        <path d="M17 13C18.0609 13 19.0783 13.4214 19.8284 14.1716C20.5786 14.9217 21 15.9391 21 17" />
    </>
)

const WarningCircle = () => (
    <>
        <path d="M12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21C9.61305 21 7.32387 20.0518 5.63604 18.364C3.94821 16.6761 3 14.3869 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3V3Z" />
        <path d="M12 12.5V7.5" />
        <path d="M11.91 16.002C11.8888 16.002 11.8679 16.0062 11.8483 16.0144C11.8288 16.0225 11.811 16.0345 11.7962 16.0496C11.7813 16.0646 11.7695 16.0825 11.7616 16.1022C11.7537 16.1218 11.7497 16.1428 11.75 16.164C11.7508 16.2062 11.7679 16.2464 11.7977 16.2763C11.8276 16.3061 11.8678 16.3232 11.91 16.324C11.953 16.324 11.9942 16.3069 12.0246 16.2766C12.0549 16.2462 12.072 16.205 12.072 16.162C12.072 16.119 12.0549 16.0778 12.0246 16.0474C11.9942 16.0171 11.953 16 11.91 16" />
    </>
)

const DollarShield = () => (
    <>
        <path d="M20 11.182C20 15.723 16.587 19.969 12 21C7.413 19.969 4 15.723 4 11.182V7.24301C4.00004 6.84594 4.11827 6.45787 4.33963 6.12823C4.561 5.79859 4.87547 5.5423 5.243 5.39201L10.243 3.34601C11.369 2.88499 12.631 2.88499 13.757 3.34601L18.757 5.39201C19.1245 5.5423 19.439 5.79859 19.6604 6.12823C19.8817 6.45787 20 6.84594 20 7.24301V11.182Z" />
        <path d="M12 8.5V7.5" />
        <path d="M12 14.5V15.5" />
        <path d="M10.264 13.785C10.523 14.209 10.965 14.502 11.5 14.502H12.596C12.9394 14.503 13.2712 14.3782 13.5287 14.1511C13.7863 13.9241 13.9517 13.6105 13.9937 13.2697C14.0357 12.929 13.9513 12.5846 13.7566 12.3018C13.5619 12.019 13.2703 11.8174 12.937 11.735L11.062 11.264C10.7305 11.18 10.441 10.9779 10.2479 10.6955C10.0548 10.4132 9.97144 10.0701 10.0134 9.73066C10.0554 9.39121 10.2199 9.07877 10.4759 8.85199C10.732 8.62521 11.062 8.49969 11.404 8.49901H12.5C13.033 8.49901 13.475 8.79101 13.733 9.21401" />
    </>
)

const MenuDots = () => (
    <>
        <path d="M11.625 12.001C11.6254 12.0751 11.6477 12.1474 11.6892 12.2088C11.7306 12.2702 11.7893 12.3179 11.8579 12.346C11.9264 12.3741 12.0018 12.3812 12.0744 12.3665C12.147 12.3519 12.2136 12.316 12.2659 12.2635C12.3181 12.2109 12.3536 12.1441 12.3679 12.0714C12.3822 11.9987 12.3747 11.9234 12.3463 11.855C12.3178 11.7866 12.2698 11.7282 12.2081 11.6871C12.1465 11.6459 12.0741 11.624 12 11.624C11.9004 11.6245 11.805 11.6645 11.7347 11.7351C11.6644 11.8058 11.625 11.9014 11.625 12.001" />
        <path d="M11.625 6.001C11.6254 6.07508 11.6477 6.14739 11.6892 6.20879C11.7306 6.2702 11.7893 6.31795 11.8579 6.34603C11.9264 6.3741 12.0018 6.38124 12.0744 6.36655C12.147 6.35185 12.2136 6.31598 12.2659 6.26346C12.3181 6.21094 12.3536 6.14411 12.3679 6.07143C12.3822 5.99874 12.3747 5.92344 12.3463 5.85504C12.3178 5.78663 12.2698 5.72818 12.2081 5.68706C12.1465 5.64594 12.0741 5.624 12 5.624C11.9004 5.62453 11.805 5.66448 11.7347 5.73513C11.6644 5.80577 11.625 5.90136 11.625 6.001" />
        <path d="M11.625 18.001C11.6254 18.0751 11.6477 18.1474 11.6892 18.2088C11.7306 18.2702 11.7893 18.3179 11.8579 18.346C11.9264 18.3741 12.0018 18.3812 12.0744 18.3665C12.147 18.3519 12.2136 18.316 12.2659 18.2635C12.3181 18.2109 12.3536 18.1441 12.3679 18.0714C12.3822 17.9987 12.3747 17.9234 12.3463 17.855C12.3178 17.7866 12.2698 17.7282 12.2081 17.6871C12.1465 17.6459 12.0741 17.624 12 17.624C11.9004 17.6245 11.805 17.6645 11.7347 17.7351C11.6644 17.8058 11.625 17.9014 11.625 18.001" />
    </>
)

const Bin = () => (
    <path d="M16.138 21h-8.28a2 2 0 01-1.995-1.853L4.966 7H19l-.867 12.142A2 2 0 0116.138 21v0zM12 11v6M4 7h16M17 7l-1.013-2.702A2 2 0 0014.114 3H9.886a2 2 0 00-1.873 1.298L7 7M15.429 11L15 17M8.571 11L9 17" />
)

const Edit = () => (
    <>
        <path d="M17.54 10.12L13.88 6.46" />
        <path d="M6.251 21H3V17.749C3 17.484 3.105 17.229 3.293 17.042L16.627 3.707C16.7198 3.61405 16.83 3.54032 16.9513 3.49001C17.0726 3.4397 17.2027 3.4138 17.334 3.4138C17.4653 3.4138 17.5954 3.4397 17.7167 3.49001C17.838 3.54032 17.9482 3.61405 18.041 3.707L20.292 5.958C20.3849 6.05079 20.4587 6.16099 20.509 6.28231C20.5593 6.40362 20.5852 6.53367 20.5852 6.665C20.5852 6.79633 20.5593 6.92638 20.509 7.04769C20.4587 7.16901 20.3849 7.27921 20.292 7.372L6.958 20.707C6.86526 20.8 6.75506 20.8738 6.63373 20.924C6.5124 20.9743 6.38234 21.0001 6.251 21V21Z" />
    </>
)

const Cross = () => (
    <>
        <path d="M6 6L18 18" />
        <path d="M18 6L6 18" />
    </>
)

const Globe = () => (
    <>
        <path d="M12 3.1C16.667 8.027 16.667 15.973 12 20.9" />
        <path d="M12 20.9C7.33301 15.973 7.33301 8.027 12 3.1" />
        <path d="M12 3C16.982 3 21 7.018 21 12C21 16.982 16.982 21 12 21" />
        <path d="M12 21C7.018 21 3 16.982 3 12C3 7.018 7.018 3 12 3" />
        <path d="M3.51001 9H20.49" />
        <path d="M3.51001 15H20.49" />
    </>
)

const Logout = () => (
    <>
        <path d="M3 12h11" />
        <path d="M11 9l3 3-3 3" />
        <path d="M6 9.137V7.4a2 2 0 0 1 1.608-1.96l11-2.406A2.146 2.146 0 0 1 21 5.2V19a2 2 0 0 1-2.339 1.972l-11-1.892A2 2 0 0 1 6 17.108v-2.137" />
    </>
)

const Send = () => (
    <>
        <path d="M3 7H2M4 4H2M17.438 16H6.85a2.399 2.399 0 0 1-2.299-3.09l2.16-7.2A2.4 2.4 0 0 1 9.01 4h10.589a2.4 2.4 0 0 1 2.299 3.09l-2.16 7.2a2.402 2.402 0 0 1-2.3 1.71v0ZM4.743 14.721l6.954-4.636M14.21 10.053l5.072 5.072M17 20H3" />
        <path d="m7.198 4.836 4.803 5.604a1 1 0 0 0 1.296.193l8.412-5.348" />
    </>
)

const PlusCircle = () => (
    <path d="M12 8v8M16 12H8M12 21a9 9 0 1 1 0-18 9 9 0 0 1 0 18v0Z" />
)

const Gift = () => (
    <path d="M18 7h-.35A3.45 3.45 0 0 0 18 5.5a3.49 3.49 0 0 0-6-2.44A3.49 3.49 0 0 0 6 5.5 3.45 3.45 0 0 0 6.35 7H6a3 3 0 0 0-3 3v2a1 1 0 0 0 1 1h1v6a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-6h1a1 1 0 0 0 1-1v-2a3 3 0 0 0-3-3Zm-7 13H8a1 1 0 0 1-1-1v-6h4v7Zm0-9H5v-1a1 1 0 0 1 1-1h5v2Zm0-4H9.5A1.5 1.5 0 1 1 11 5.5V7Zm2-1.5A1.5 1.5 0 1 1 14.5 7H13V5.5ZM17 19a1 1 0 0 1-1 1h-3v-7h4v6Zm2-8h-6V9h5a1 1 0 0 1 1 1v1Z" fill="currentColor" stroke="none" />
)

const Icon = ({
    name,
    size = 'default',
    className,
    ...props
}) => (
    <svg
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.75"
        className={(clsx(
            variant(size, 'h-6 w-6', {
                largest: 'h-12 w-12',
                larger: 'h-10 w-10',
                large: 'h-8 w-8',
                small: 'h-5 w-5',
            }),
            className,
        ))}
        {...props}
    >
        {(() => {
            switch (name) {
                case 'check':
                    return <Check />
                case 'check-circle':
                    return <CheckCircle />
                case 'chevron-down':
                    return <ChevronDown />
                case 'chevron-left':
                    return <ChevronLeft />
                case 'chevron-right':
                    return <ChevronRight />
                case 'file':
                    return <File />
                case 'search':
                    return <Search />
                case 'sync':
                    return <Sync />
                case 'user':
                    return <User />
                case 'users':
                    return <Users />
                case 'warning-circle':
                    return <WarningCircle />
                case 'dollar-shield':
                    return <DollarShield />
                case 'menu-dots':
                    return <MenuDots />
                case 'bin':
                    return <Bin />
                case 'edit':
                    return <Edit />
                case 'cross':
                    return <Cross />
                case 'spinner':
                    return <Spinner />
                case 'globe':
                    return <Globe />
                case 'logout':
                    return <Logout />
                case 'send':
                    return <Send />
                case 'plus-circle':
                    return <PlusCircle />
                case 'gift':
                    return <Gift />
                default:
                    return null
            }
        })()}
    </svg>
)

export default Icon
