import { format } from 'date-fns'

export const formatGraphQlDateTimeString = (
    dateTimeString,
    inputFormat = 'yyyy-MM-dd HH:mm',
) => {
    if (!dateTimeString) {
        return ''
    }
    return format(new Date(dateTimeString), inputFormat)
}

export const formatGraphQlPrice = (price) => `€${price / 100}`.replace('.', ',')

export const formatSBPrice = (price) => price && `€${price}`
