import React from 'react'
import useNotifications from '../../hooks/useNotifications'
import CreateForkModal from './CreateForkModal'
import useCreateForkFormState from './useCreateForkFormState'
import useCreateForkMutation from './useCreateForkMutation'

const CreateForkModalWithState = ({
    isOpen,
    onClose,
    onCompleted,
    onError,
}) => {
    const [createFork, { loading }] = useCreateForkMutation({ onCompleted, onError })
    const formState = useCreateForkFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateForkModal
            isOpen={isOpen}
            onClose={() => {
                onClose()
                formState.resetForm()
            }}
            isLoading={loading}
            formState={formState}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const {
                            code,
                            min,
                            max,
                            blockedAirlines,
                        } = formState.values
                        const variables = {
                            input: {
                                code,
                                min: parseInt(min, 10),
                                max: parseInt(max, 10),
                                blockedAirlines,
                            }
                        }
                        await createFork({
                            variables,
                        })
                        dispatchSuccess()
                        onClose()
                    } catch (error) {
                        dispatchGraphqlError(error, {}, true)
                    }
                }
            }}
        />
    )
}

export default CreateForkModalWithState
