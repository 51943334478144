import React from 'react'
import { Trans } from '@lingui/macro'
import Tabs from '../../utils/Tabs'
import PageTitle from '../../utils/PageTitle'
import TabsItemLink from '../../utils/TabsItemLink'

const BookingsPageHeader = () => (
    <>
        <PageTitle>
            <Trans>Bookings</Trans>
        </PageTitle>
        <Tabs className="mb-8">
            <TabsItemLink exact to="/bookings">
                <Trans>Completed</Trans>
            </TabsItemLink>
            <TabsItemLink exact to="/bookings/no-pax-file">
                <Trans>Without pax file</Trans>
            </TabsItemLink>
        </Tabs>
    </>
)

export default BookingsPageHeader
