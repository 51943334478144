import React from 'react'
import { Trans } from '@lingui/macro'
import Modal, { ModalTitle } from './Modal'
import Button from './Button'

const ConfirmModal = ({
    isOpen,
    onClose,
    title,
    description,
    isLoading,
    isDisabled,
    variant = 'danger',
    confirmButtonText = <Trans>Ok</Trans>,
    cancelButtonText = <Trans>Cancel</Trans>,
    onConfirm,
    children,
}) => (
    <Modal
        isOpen={isOpen}
        onClose={onClose}
        className="max-w-lg"
    >
        <ModalTitle
            title={title}
            description={description}
            isDisabled={isDisabled}
            onClose={onClose}
        />
        {children}
        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <Button
                    variant={variant}
                    onClick={onConfirm}
                    loading={isLoading}
                    disabled={isDisabled}
                >
                    {confirmButtonText}
                </Button>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <Button
                    variant="white"
                    onClick={onClose}
                    loading={isLoading}
                    disabled={isDisabled}
                >
                    {cancelButtonText}
                </Button>
            </span>
        </div>
    </Modal>
)

export default ConfirmModal
