import React from 'react'
import Icon from './Icon'

const EmptyTableCard = ({
    iconName,
    title,
    description,
    children
}) => (
    <div className="px-6 py-14 text-center flex flex-col items-center">
        <Icon
            name={iconName}
            size="large"
        />
        <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
            {title}
        </h3>
        <p className="mt-2 max-w-lg text-sm leading-5 text-gray-500">
            {description}
        </p>
        {children}
    </div>
)

export default EmptyTableCard
