import React, { Fragment } from 'react'
import clsx from 'clsx'
import { Dialog, Transition } from '@headlessui/react'
import Notifications from './Notifications'

const Modal = ({
    isOpen,
    children,
    className,
    ...props
}) => (
    <Transition show={isOpen} as={Fragment}>
        <Dialog
            open={isOpen}
            className="fixed inset-0 z-10 overflow-y-auto"
            static
            {...props}
        >
            <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
                </Transition.Child>
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div
                        className={clsx(
                            'inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl',
                            className,
                        )}
                    >
                        {children}
                    </div>
                </Transition.Child>
            </div>
            <Notifications />
        </Dialog>
    </Transition>
)

export const ModalTitle = ({
    title,
    isDisabled,
    description,
    onClose,
}) => (
    <>
        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
                type="button"
                onClick={onClose}
                disabled={isDisabled}
                className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                aria-label="Close"
            >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
        <div className="mt-3 mb-6 text-center sm:mt-0 sm:text-left">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                {title}
            </h3>
            <p className="mt-2 text-sm leading-5 text-gray-500">
                {description}
            </p>
        </div>
    </>
)

export const ModalBody = (props) => (
    <div className="space-y-4 mt-6 mb-10" {...props} />
)

export const ModalFooter = (props) => (
    <div className="flex justify-end" {...props} />
)

export default Modal
