import React from 'react'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import Alert from '../../utils/Alert'
import Button from '../../utils/Button'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormCheckbox from '../../utils/FormCheckbox'
import LoginPageTitle from './LoginPageTitle'
import LoginPageContainer from './LoginPageContainer'

const LoginPage = ({
    formState,
    errorMessage,
    isLoading,
    onSubmit,
}) => {
    const {
        getErrorMessages,
        getNativeInputProps,
    } = formState
    return (
        <LoginPageContainer>
            <LoginPageTitle className="mb-4">
                <Trans>Login</Trans>
            </LoginPageTitle>
            <div>
                {errorMessage && (
                    <Alert
                        className="mb-6"
                        title={<Trans>Failed to login</Trans>}
                    >
                        {errorMessage}
                    </Alert>
                )}
                <div className="space-y-6">
                    <FormGroup
                        label={<Trans>Email address</Trans>}
                        htmlFor="email"
                    >
                        <FormInput
                            id="email"
                            className="max-w-lg"
                            errors={getErrorMessages('email')}
                            {...getNativeInputProps('email')}
                        />
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Password</Trans>}
                        htmlFor="password"
                    >
                        <FormInput
                            id="password"
                            type="password"
                            className="max-w-lg"
                            errors={getErrorMessages('password')}
                            {...getNativeInputProps('password')}
                        />
                    </FormGroup>
                    <div className="mt-6 flex items-center justify-between">
                        <div className="flex items-center">
                            <FormCheckbox
                                label={<Trans>Remember me</Trans>}
                                {...getNativeInputProps('rememberMe')}
                            />
                        </div>
                        <div className="text-sm leading-5">
                            <Link to="/reset-password">
                                <button
                                    type="button"
                                    className="font-medium text-primary hover:text-primary-700 focus:outline-none focus:underline transition ease-in-out duration-150"
                                >
                                    <Trans>Forgot your password?</Trans>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="mt-6">
                        <Button
                            type="submit"
                            data-testid="SignInButton"
                            onClick={onSubmit}
                            isLoading={isLoading}
                            disabled={isLoading}
                            className="w-full"
                        >
                            <Trans>Sign in</Trans>
                        </Button>
                    </div>
                </div>
            </div>
        </LoginPageContainer>
    )
}

export default LoginPage
