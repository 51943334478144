import { useQuery, gql } from '@apollo/client'
import PageInfo from '../../../apollo/PageInfoFragment'
import { connectionToCollection } from '../../../utils/graphql'
import useFetchMore from '../../hooks/useFetchMore'
import FlightSearchResultsPageTableRow from './FlightSearchResultsPageTableRow'

export const FLIGHT_SEARCH_RESULTS_PAGE_QUERY = gql`
    query flightSearchResultsPageQuery($after: String, $first: Int, $airlineCodes: [String], $arrivalCodes: [String]) {
        flightSearchResults(after: $after, first: $first, airlineCodes: $airlineCodes, arrivalCodes: $arrivalCodes) {
            totalCount
            edges {
                cursor
                node {
                    ...FlightSearchResultsPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
        flightSearchResultsAirlines {
            id
            code
            name
        }
        flightSearchResultsAirports {
            id
            code
            name
        }
    }
    ${PageInfo}
    ${FlightSearchResultsPageTableRow.FRAGMENT}
`

const useFlightSearchResultsPageQuery = (airlineCode, arrivalCode) => {
    const variables = {
        airlineCodes: airlineCode ? [airlineCode] : [],
        arrivalCodes: arrivalCode ? [arrivalCode] : [],
    }
    const {
        data = {},
        error,
        loading,
        refetch,
        fetchMore,
    } = useQuery(FLIGHT_SEARCH_RESULTS_PAGE_QUERY, { variables })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(fetchMore, data, 'flightSearchResults')
    return {
        error,
        refetch,
        isFetchingMore,
        handleFetchMore,
        isFetching: loading,
        hasMore: !loading && !error && hasNextPage,
        flightSearchResults: connectionToCollection(data.flightSearchResults),
        flightSearchResultsAirlines: data.flightSearchResultsAirlines || [],
        flightSearchResultsAirports: data.flightSearchResultsAirports || [],
    }
}

export default useFlightSearchResultsPageQuery
