import React from 'react'

const PageTitle = ({
    children,
    rightChildren,
}) => (
    <div className="py-8 flex items-center justify-between gap-4">
        <div>
            <h2 className="text-3xl font-medium leading-9 text-gray-900 truncate">
                {children}
            </h2>
        </div>
        <div>
            {rightChildren}
        </div>
    </div>
)

export default PageTitle
