import React from 'react'
import Spinner from './Spinner'

const TableLoading = () => (
    <div className="px-6 py-14 text-center flex flex-col items-center">
        <Spinner size="large" />
    </div>
)

export default TableLoading
