import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import AddPaxFileIdToBookingModal from './AddPaxFileIdToBookingModal'
import useAddPaxFileIdToBookingMutation from './useAddPaxFileIdToBookingMutation'

const AddPaxFileIdToBookingModalWithState = ({
    isOpen,
    onClose,
    bookingUid,
}) => {
    const [paxFileId, setPaxFileId] = useState('')
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [addPaxFileIdToBooking, { loading }] = useAddPaxFileIdToBookingMutation()
    return (
        <AddPaxFileIdToBookingModal
            isOpen={isOpen}
            onClose={onClose}
            isLoading={loading}
            paxFileId={paxFileId}
            onChangePaxFileId={setPaxFileId}
            onSubmit={async () => {
                try {
                    const variables = {
                        paxFileId,
                        bookingUid,
                    }
                    await addPaxFileIdToBooking({ variables })
                    dispatchSuccess({
                        message: <Trans>Booking successfully updated.</Trans>
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                } finally {
                    onClose()
                }
            }}
        />
    )
}

export default AddPaxFileIdToBookingModalWithState
