import React from 'react'
import {
    Route,
    Switch,
    Redirect,
    BrowserRouter,
} from 'react-router-dom'
import * as constants from '@connections/constants'
import LoginPageWithState from './pages/login/LoginPageWithState'
import AcceptInvitationPageWithState from './pages/invitation-accept/AcceptInvitationPageWithState'
import AdminRouter from './AdminRouter'
import ShopRouter from './ShopRouter'

const {
    ADMIN_ROLE_MEMBER: MEMBER,
    ADMIN_ROLE_OWNER: OWNER,
    ADMIN_ROLE_SHOP: SHOP,
} = constants

const AppRouter = ({
    history,
    currentUser = null,
}) => {
    const pageProps = { currentUser }
    if (currentUser === null) {
        return (
            <BrowserRouter history={history}>
                <Switch>
                    <Route exact path="/">
                        <LoginPageWithState {...pageProps} />
                    </Route>
                    <Route exact path="/login">
                        <LoginPageWithState {...pageProps} />
                    </Route>
                    <Route exact path="/invitation/:id/accept">
                        <AcceptInvitationPageWithState {...pageProps} />
                    </Route>
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </BrowserRouter>
        )
    }
    switch (currentUser.role) {
        case SHOP:
            return (
                <BrowserRouter history={history}>
                    <ShopRouter {...pageProps} />
                </BrowserRouter>
            )
        case OWNER:
        case MEMBER:
            return (
                <BrowserRouter history={history}>
                    <AdminRouter {...pageProps} />
                </BrowserRouter>
            )
        default:
            return null
    }
}

export default AppRouter
