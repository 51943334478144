import {
    HttpLink,
    ApolloClient,
    InMemoryCache,
    ApolloLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import * as storage from '../utils/storage'
import { isUnauthenticatedError } from '../utils/graphql'
import { STORYBLOK_CLIENT } from '@connections/constants'

const mergeStoryblokGql = (apiLink) => {
    const storyblokLink = new HttpLink({
        uri: process.env.NEXT_PUBLIC_STORYBLOK_GRAPHQL_URI,
    })

    const authMiddleWare = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                token: process.env.NEXT_PUBLIC_STORYBLOK_GRAPHQL_TOKEN,
                version: 'published',
            },
        }))
        return forward(operation)
    })

    return ApolloLink.split(
        (operation) => operation.getContext().client === STORYBLOK_CLIENT,
        authMiddleWare.concat(storyblokLink),
        apiLink,
    )
}

export const createUnauthorizedApolloClient = () => (
    new ApolloClient({
        cache: new InMemoryCache(),
        link: mergeStoryblokGql(new HttpLink({
            uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
        })),
    })
)

export default function createApolloClient() {
    const {
        NEXT_PUBLIC_GRAPHQL_URI,
    } = process.env
    const setAuthContext = async (request, { headers }) => {
        let adminHeaders = {
            ...headers
        }
        const accessToken = await storage.getAccessToken()
        if (accessToken !== null) {
            adminHeaders = {
                ...adminHeaders,
                authorization: `Bearer ${accessToken}`,
            }
        }
        return { headers: adminHeaders }
    }

    const removeJwtTokenFromCacheLink = onError((error) => {
        const { graphQLErrors } = error
        if (typeof graphQLErrors !== 'undefined' && graphQLErrors.length > 0) {
            graphQLErrors.map(async (graphQLError) => {
                if (isUnauthenticatedError(graphQLError)) {
                    storage.removeAccessToken()
                    storage.removeRefreshToken()
                }
            })
        }
        // eslint-disable-next-line no-console
        console.warn({ error })
    })

    const httpLink = new HttpLink({
        uri: NEXT_PUBLIC_GRAPHQL_URI,
    })

    const setAuthorizationHeadersLink = setContext(setAuthContext)

    const linkFlow = setAuthorizationHeadersLink.concat(
        removeJwtTokenFromCacheLink.concat(
            httpLink
        )
    )
    return new ApolloClient({
        link: mergeStoryblokGql(linkFlow),
        cache: new InMemoryCache()
    })
}
