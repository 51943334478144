import React, { useState } from 'react'
import usePermissions from '../../hooks/usePermissions'
import UsersPageHeader from './UsersPageHeader'
import CreateInvitationModalWithState from './CreateInvitationModalWithState'

const UsersPageHeaderWithState = () => {
    const { canCreateInvitation } = usePermissions()
    const [createInvitationModalIsOpen, setCreateInvitationModalIsOpen] = useState(false)
    return (
        <>
            <UsersPageHeader
                canCreateInvitation={canCreateInvitation}
                onCreateInvitation={() => setCreateInvitationModalIsOpen(true)}
            />
            <CreateInvitationModalWithState
                isOpen={createInvitationModalIsOpen}
                onClose={() => setCreateInvitationModalIsOpen(false)}
            />
        </>
    )
}

export default UsersPageHeaderWithState
