import React from 'react'
import useNotifications from '../../hooks/useNotifications'
import CreateGiftcardModal from './CreateGiftcardModal'
import useGiftcardFormState from './useGiftcardFormState'
import useCreateGiftcardMutation from './useCreateGiftcardMutation'

const CreateGiftcardModalWithState = ({
    isOpen,
    onClose,
    onCompleted,
    onError,
    imageList = [],
}) => {
    const imageListWithContext = imageList.filter(({ context }) => Boolean(context))
    const headerImage = imageListWithContext?.find(({ context }) => context.custom?.key === 'header').public_id || ''
    const footerImage = imageListWithContext?.find(({ context }) => context.custom?.key === 'footer').public_id || ''
    const formState = useGiftcardFormState({ headerImage, footerImage })
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [createGiftcard, { loading }] = useCreateGiftcardMutation({ onCompleted, onError })

    const handleSubmit = async () => {
        if (formState.validate()) {
            try {
                const variables = {
                    input: formState.valuesToInput()
                }
                await createGiftcard({
                    variables,
                })
                dispatchSuccess()
                onClose()
            } catch (error) {
                dispatchGraphqlError(error, {}, true)
            }
        }
    }

    return (
        <CreateGiftcardModal
            isOpen={isOpen}
            onClose={() => {
                formState.resetForm()
                onClose()
            }}
            isLoading={loading}
            formState={formState}
            onSubmit={handleSubmit}
            imageList={imageListWithContext}
        />
    )
}

export default CreateGiftcardModalWithState
