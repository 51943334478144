import React from 'react'
import { Trans } from '@lingui/macro'
import Tabs from '../../utils/Tabs'
import Button from '../../utils/Button'
import PageTitle from '../../utils/PageTitle'
import TabsItemLink from '../../utils/TabsItemLink'

const UsersPageHeader = ({
    onCreateInvitation,
    canCreateInvitation,
}) => (
    <>
        <div className="flex justify-between items-center">
            <PageTitle>
                <Trans>Users</Trans>
            </PageTitle>
            {canCreateInvitation && (
                <div>
                    <Button
                        data-testid="InviteUserBtn"
                        onClick={onCreateInvitation}
                    >
                        <Trans>Invite User</Trans>
                    </Button>
                </div>
            )}
        </div>
        <Tabs className="mb-8">
            <TabsItemLink exact to="/users">
                <Trans>Active</Trans>
            </TabsItemLink>
            <TabsItemLink exact to="/users/invitations">
                <Trans>Pending Invitations</Trans>
            </TabsItemLink>
        </Tabs>
    </>
)

export default UsersPageHeader
