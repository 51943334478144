import React from 'react'
import PageContent from '../../utils/PageContent'
import TableLoading from '../../utils/TableLoading'
import PageContainer from '../../utils/PageContainer'
import PageNavigation from '../../utils/PageNavigation'
import UsersPageTable from './UsersPageTable'
import UsersPageHeaderWithState from './UsersPageHeaderWithState'

const UsersPage = ({
    users,
    hasMore,
    isFetching,
    onFetchMore,
    isFetchingMore,
    onDeleteUserSuccess,
}) => (
    <PageContainer>
        <PageNavigation />
        <PageContent>
            <UsersPageHeaderWithState />
            {isFetching ? (
                <TableLoading />
            ) : (
                <UsersPageTable
                    users={users}
                    hasMore={hasMore}
                    onFetchMore={onFetchMore}
                    isFetchingMore={isFetchingMore}
                    onDeleteUserSuccess={onDeleteUserSuccess}
                />
            )}
        </PageContent>
    </PageContainer>
)

export default UsersPage
