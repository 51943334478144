import React from 'react'
import NextHead from 'next/head'

const AppHead = ({
    title = 'Connections Admin',
    description = 'Connections Admin',
    keywords,
    authors,
    themeColor = '#08559D',
}) => {
    let faviconPostfix = ''
    if (process.env.NEXT_PUBLIC_APP_ENV === 'staging') {
        faviconPostfix = '-staging'
    }
    return (
        <NextHead>
            <meta name="robots" content="index, follow" />
            <meta name="viewport" content="minimum-scale=1, width=device-width, initial-scale=1" />

            <title>{title}</title>

            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="author" content={authors} />
            <meta name="theme-color" content={themeColor} />

            <link rel="manifest" href="/site.webmanifest" />

            <link rel="shortcut icon" type="image/x-icon" href={`/favicon${faviconPostfix}.ico`} />
            <link rel="shortcut icon" type="image/png" href={`/favicon${faviconPostfix}-16x16.png`} sizes="16x16" />
            <link rel="shortcut icon" type="image/png" href={`/favicon${faviconPostfix}-32x32.png`} sizes="32x32" />
            <link rel="shortcut icon" type="image/png" href={`/favicon${faviconPostfix}-48x48.png`} sizes="48x48" />
            <link rel="shortcut icon" type="image/png" href={`/favicon${faviconPostfix}-96x96.png`} sizes="96x96" />
            <link rel="shortcut icon" type="image/png" href={`/favicon${faviconPostfix}-144x144.png`} sizes="144x144" />
            <link rel="apple-touch-icon" sizes="180x180" href={`/apple-touch-icon${faviconPostfix}.png`} />

            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link rel="preload" as="style" href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" />
        </NextHead>
    )
}

export default AppHead
