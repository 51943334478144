import * as constants from '@connections/constants'
import useAppState from './useAppState'

const {
    ADMIN_ROLE_OWNER: OWNER,
    ADMIN_ROLE_MEMBER: MEMBER,
    ADMIN_ROLE_SHOP: SHOP,
} = constants

const useRoles = () => {
    const { currentUser } = useAppState()
    const isAdmin = currentUser?.role === OWNER || currentUser?.role === MEMBER
    const isShopUser = currentUser?.role === SHOP

    return {
        isAdmin,
        isShopUser,
    }
}

export default useRoles
