import React from 'react'
import { Trans } from '@lingui/macro'
import Modal, { ModalTitle, ModalBody } from '../../utils/Modal'
import Button from '../../utils/Button'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormSelect from '../../utils/FormSelect'

const CreateInvitationModal = ({
    isOpen,
    onClose,
    isCreatingInvitation,
    formState,
    roles,
    onSubmit,
}) => {
    const {
        getErrorMessages,
        getNativeInputProps,
    } = formState
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalTitle
                title={<Trans>Invite a new user</Trans>}
                onClose={onClose}
            />
            <ModalBody>
                <FormGroup
                    label={<Trans>Email address</Trans>}
                    htmlFor="email"
                >
                    <FormInput
                        id="email"
                        className="max-w-lg"
                        errors={getErrorMessages('email')}
                        {...getNativeInputProps('email')}
                    />
                </FormGroup>
                <FormGroup
                    label={<Trans>Role</Trans>}
                    htmlFor="role"
                >
                    <FormSelect
                        id="role"
                        errors={getErrorMessages('roleId')}
                        {...getNativeInputProps('roleId')}
                    >
                        {roles.map(({ id, type }) => (
                            <option key={id} value={id}>{type}</option>
                        ))}
                    </FormSelect>
                </FormGroup>
            </ModalBody>
            <div className="flex justify-end">
                <Button
                    className="mr-4"
                    variant="white"
                    onClick={onClose}
                    disabled={isCreatingInvitation}
                >
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    onClick={onSubmit}
                    isLoading={isCreatingInvitation}
                    disabled={isCreatingInvitation}
                >
                    <Trans>Invite</Trans>
                </Button>
            </div>
        </Modal>
    )
}

export default CreateInvitationModal
