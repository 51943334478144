import React, { useState } from 'react'
import usePermissions from '../../hooks/usePermissions'
import CreateInvitationModalWithState from '../users/CreateInvitationModalWithState'
import InvitationsPage from './InvitationsPage'
import useInvitationsPageQuery from './useInvitationsPageQuery'

const UsersPageWithState = () => {
    const { canCreateInvitation } = usePermissions()
    const [isInvitingUser, setIsInvitingUser] = useState(false)
    const {
        refetch,
        hasMore,
        isFetching,
        invitations,
        isFetchingMore,
        handleFetchMore,
    } = useInvitationsPageQuery()
    return (
        <>
            <InvitationsPage
                hasMore={hasMore}
                isFetching={isFetching}
                invitations={invitations}
                onDeleteUserSuccess={refetch}
                onFetchMore={handleFetchMore}
                isFetchingMore={isFetchingMore}
                onResendInvitationSuccess={refetch}
                canCreateInvitation={canCreateInvitation}
                onInviteUser={() => setIsInvitingUser(true)}
            />
            {isInvitingUser && (
                <CreateInvitationModalWithState
                    isOpen
                    onClose={() => setIsInvitingUser(false)}
                />
            )}
        </>
    )
}

export default UsersPageWithState
