import React from 'react'
import Table from '../../utils/Table'
import InfiniteScroll from '../../utils/InfiniteScroll'
import UsersPageTableRow from './UsersPageTableRow'
import UsersPageTableHeaderRow from './UsersPageTableHeaderRow'

const UsersPageTable = ({
    users,
    hasMore,
    onFetchMore,
    isFetchingMore,
    onDeleteUserSuccess,
}) => (
    <InfiniteScroll
        hasMore={hasMore}
        onFetchMore={onFetchMore}
        isFetchingMore={isFetchingMore}
    >
        <Table data-testid="InvitationsPageTable">
            <thead>
                <UsersPageTableHeaderRow />
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user) => (
                    <UsersPageTableRow
                        user={user}
                        key={user.id}
                        onDeleteUserSuccess={onDeleteUserSuccess}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default UsersPageTable
