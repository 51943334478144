import React from 'react'
import clsx from 'clsx'
import { gql } from '@apollo/client'
import * as constants from '@connections/constants'
import TableCell from '../../utils/TableCell'
import InvitationsPageTableRowActionButtonWithState from './InvitationsPageTableRowActionButtonWithState'

const {
    INVITATION_STATUS_PENDING: PENDING,
    INVITATION_STATUS_EXPIRED: EXPIRED,
    INVITATION_STATUS_ACCEPTED: ACCEPTED,
} = constants

const FRAGMENT = gql`
    fragment InvitationsPageTableRow on Invitation {
        id
        email
        role {
            id
            type
        }
        status
    }
`

const InvitationsPageTableRow = ({
    invitation,
    onDeleteUserSuccess,
    onResendInvitationSuccess,
}) => {
    const {
        email,
        role,
        status,
    } = invitation
    return (
        <tr data-testid="InvitationsPageTableRow">
            <TableCell hasText>
                {email}
            </TableCell>
            <TableCell hasText>
                {role && role.type}
            </TableCell>
            <TableCell>
                <span
                    className={clsx(
                        'px-2 inline-flex text-xs leading-5 font-semibold rounded-full',
                        status === PENDING && 'bg-blue-100 text-blue-800',
                        status === EXPIRED && 'bg-yellow-100 text-yellow-800',
                        status === ACCEPTED && 'bg-blue-100 text-blue-800',
                    )}
                >
                    {status}
                </span>
            </TableCell>
            <TableCell hasText className="text-right">
                <InvitationsPageTableRowActionButtonWithState
                    invitation={invitation}
                    onDeleteUserSuccess={onDeleteUserSuccess}
                    onResendInvitationSuccess={onResendInvitationSuccess}
                />
            </TableCell>
        </tr>
    )
}

InvitationsPageTableRow.FRAGMENT = FRAGMENT

export default InvitationsPageTableRow
