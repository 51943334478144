import { useState } from 'react'

const useSBFetchMore = (fetchMore, data, key) => {
    const [isFetchingMore, setIsFetchingMore] = useState(false)
    if (!data || !data[key]) {
        return {
            handleFetchMore: () => {},
            isFetchingMore,
            hasNextPage: false,
        }
    }
    const field = data[key]
    const totalPages = Math.ceil(field.total / 25)
    const page = Math.ceil(field.items.length / 25) + 1
    const hasNextPage = page <= totalPages

    const handleFetchMore = async () => {
        setIsFetchingMore(true)
        await fetchMore({
            variables: { page },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                setIsFetchingMore(false)
                return {
                    [key]: {
                        ...previousResult[key],
                        items: [
                            ...previousResult[key].items,
                            ...fetchMoreResult[key].items,
                        ]
                    }
                }
            },
        })
        setIsFetchingMore(false)
    }

    return {
        handleFetchMore,
        isFetchingMore,
        hasNextPage,
    }
}

export default useSBFetchMore
