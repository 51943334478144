import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteInvitationModal = ({
    isOpen,
    onClose,
    isLoading,
    onConfirm,
    invitation,
}) => (
    <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        loading={isLoading}
        disabled={isLoading}
        onConfirm={onConfirm}
        title={<Trans>Delete Invitation</Trans>}
        confirmButtonText={<Trans>Delete Invitation</Trans>}
        description={<Trans>Are you sure you want to delete the invitation to <strong>{invitation.email}</strong>?</Trans>}
    />
)

export default DeleteInvitationModal
